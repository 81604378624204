import React from "react";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import ProductCard from "../components/ProductCard/ProductCard";
import { Row, Col, Container } from "react-bootstrap";
import { Assets } from "../constant/images";
import ShopNowSlider from "../components/SwiperSlider/ShopNowSlider";
import { Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { NavLink } from "react-router-dom";

const Home = (props) => {
  return (
    <>
      <div className="main-section banner-bg">
        <Container>
          <Row>
            <Col sm={12} md={7} lg={8} xl={8} xxl={8}>
              <ShopNowSlider slidenavnext="js-next1" slidenavprev="js-prev1" />
            </Col>
            <Col sm={12} md={5} lg={4} xl={4} xxl={4}>
              <div className="product-card-shop d-none d-md-block">
                <h2>Sale - 20%</h2>
                <p>Handmade table decorations for the upcoming Halloween</p>
                <div>
                  <NavLink to="/product-listing"><Button className="shop-btn">Shop Now</Button></NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="mt-5 mb-5 main-section">
        <Container>
          {/* Recently Uploaded */}
          <div className="d-block d-sm-flex align-items-center justify-content-between  mt_60 mb_16">
          <div className="product-category-heading">
            Recently Uploaded
          </div>
          <div className="show-all mt-2 mt-sm-0">
            Show All
          </div>
          </div>
          {/* <div className="product-category-desc mb_8">All Categories</div> */}
          {/* Recently Uploaded Product Swiper Responsive */}
          <div className="home-responsive-swiper d-xl-none">
          <Swiper className="mySwiper"
                  slidesPerView={3}
                  spaceBetween={30}
                  breakpoints= {{
                    320: {
                      slidesPerView:1,
                      spaceBetween:30
                    },
                    450: {
                      slidesPerView:1.5,
                      spaceBetween:30
                    },
                    600: {
                      slidesPerView:1.5,
                      spaceBetween:30
                    },
                    700: {
                      slidesPerView:2,
                      spaceBetween:30
                    },
                    870: {
                      slidesPerView:3,
                      spaceBetween:30
                    }
                  }}
               >
            <SwiperSlide>
            <ProductCard
                productimg={Assets.Bag}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                productimg={Assets.Dog}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
              <ProductCard
                productimg={Assets.Tree}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                productimg={Assets.Ball}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                productimg={Assets.Bag}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.one}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                productimg={Assets.two}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>

          </Swiper>
          </div>
          {/* Recently Uploaded Product Swiper Responsive */}
          <div  className="d-none d-xl-block">
          <Row>
            <Col lg={3}>
              <ProductCard
                productimg={Assets.Bag}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col lg={3}>
              <ProductCard
                productimg={Assets.Dog}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />{" "}
            </Col>
            <Col lg={3}>
              <ProductCard
                productimg={Assets.Tree}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />{" "}
            </Col>
            <Col lg={3}>
              <ProductCard
                productimg={Assets.Ball}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />{" "}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <ProductCard
                className="mt_30"
                productimg={Assets.one}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={6}>
              <ProductCard
                productimg={Assets.two}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
          </Row>
          </div>
          {/* More Products */}
          <div className="d-block d-sm-flex align-items-center justify-content-between mt_30 mb_8 mb_16">
          <div className="product-category-heading">
            More Products
          </div>
          <div className="show-all mt-2 mt-sm-0">Show All</div>
          </div>
          <div className="d-none d-xl-block">
          <Row>
            <Col md={4}>
              <ProductCard
                className="mt_30"
                productimg={Assets.three}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={4}>
              <ProductCard
                className="mt_30"
                productimg={Assets.four}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={4}>
              <ProductCard
                className="mt_30"
                productimg={Assets.five}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <ProductCard
                className="mt_30"
                productimg={Assets.six}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={6}>
              <ProductCard
                className="mt_30"
                productimg={Assets.seven}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <ProductCard
                className="mt_30"
                productimg={Assets.eight}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={3}>
              <ProductCard
                className="mt_30"
                productimg={Assets.nine}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={3}>
              <ProductCard
                className="mt_30"
                productimg={Assets.ten}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={3}>
              <ProductCard
                className="mt_30"
                productimg={Assets.eleven}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
          </Row>
          </div>
          {/* More Product Swiper Responsive */}
          <div className="home-responsive-swiper d-xl-none">
          <Swiper className="mySwiper"
                  slidesPerView={3}
                  spaceBetween={30}
                  breakpoints= {{
                    320: {
                      slidesPerView:1,
                      spaceBetween:30
                    },
                    450: {
                      slidesPerView:1.5,
                      spaceBetween:30
                    },
                    600: {
                      slidesPerView:1.5,
                      spaceBetween:30
                    },
                    700: {
                      slidesPerView:2,
                      spaceBetween:30
                    },
                    870: {
                      slidesPerView:3,
                      spaceBetween:30
                    }
                  }}
               >
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.three}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.four}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.five}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.six}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.seven}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.eight}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.nine}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.ten}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>

          </Swiper>
          </div>
          {/* More Product Swiper Responsive */}


          {/* Most Ordered */}
          <div className="mt_30 mb_16 d-block d-sm-flex align-items-center justify-content-between">
          <div className="product-category-heading ">
            Most Ordered
          </div>
          <div className="show-all mt-2 mt-sm-0">Show All</div>
          </div>
          <div className="d-none d-xl-block">
            
          <Row>
            <Col md={3}>
              <ProductCard
                className="mt_30"
                productimg={Assets.twelve}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={3}>
              <ProductCard
                className="mt_30"
                productimg={Assets.thirteen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={3}>
              <ProductCard
                className="mt_30"
                productimg={Assets.fourteen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={3}>
              <ProductCard
                className="mt_30"
                productimg={Assets.fifteen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <ProductCard
                className="mt_30"
                productimg={Assets.sixteen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={4}>
              <ProductCard
                className="mt_30"
                productimg={Assets.seventeen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={4}>
              <ProductCard
                className="mt_30"
                productimg={Assets.eighteen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
          </Row>
          </div>
          {/* More Product Swiper Responsive */}
          <div className="home-responsive-swiper d-xl-none">
          <Swiper className="mySwiper"
                  slidesPerView={3}
                  spaceBetween={30}
                  breakpoints= {{
                    320: {
                      slidesPerView:1,
                      spaceBetween:30
                    },
                    450: {
                      slidesPerView:1.5,
                      spaceBetween:30
                    },
                    600: {
                      slidesPerView:1.5,
                      spaceBetween:30
                    },
                    700: {
                      slidesPerView:2,
                      spaceBetween:30
                    },
                    870: {
                      slidesPerView:3,
                      spaceBetween:30
                    }
                  }}
               >
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.twelve}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.thirteen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.fourteen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.fifteen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.sixteen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.seventeen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.eighteen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
          </Swiper>
          </div>
          {/* More Product Swiper Responsive */}

          {/* Most Popular */}
          <div className="d-block d-sm-flex align-items-center justify-content-between mt_30 mb_16">
          <div className="product-category-heading">
            Most Popular
          </div>
          <div className="show-all mt-2 mt-sm-0">Show All</div>
          </div>

          <div className="d-none d-xl-block">
          <Row>
            <Col md={3}>
              <ProductCard
                className="mt_30"
                productimg={Assets.nineteen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={3}>
              <ProductCard
                className="mt_30"
                productimg={Assets.twenty}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={3}>
              <ProductCard
                className="mt_30"
                productimg={Assets.twentyone}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={3}>
              <ProductCard
                className="mt_30"
                productimg={Assets.twentytwo}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <ProductCard
                className="mt_30"
                productimg={Assets.twentythree}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={6}>
              <ProductCard
                className="mt_30"
                productimg={Assets.twentyfour}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <ProductCard
                className="mt_30"
                productimg={Assets.twentyfive}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={4}>
              <ProductCard
                className="mt_30"
                productimg={Assets.twentysix}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
            <Col md={4}>
              <ProductCard
                className="mt_30"
                productimg={Assets.twentyseven}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </Col>
          </Row>
          </div>

          {/* Most Popular Swiper Responsive */}
          <div className="home-responsive-swiper d-xl-none">
          <Swiper className="mySwiper"
                  slidesPerView={1}
                  spaceBetween={30}
                  breakpoints= {{
                    320: {
                      slidesPerView:1,
                      spaceBetween:30
                    },
                    450: {
                      slidesPerView:1.5,
                      spaceBetween:30
                    },
                    600: {
                      slidesPerView:1.5,
                      spaceBetween:30
                    },
                    700: {
                      slidesPerView:2,
                      spaceBetween:30
                    },
                    870: {
                      slidesPerView:3,
                      spaceBetween:30
                    }
                  }}
               >
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.nineteen}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.twenty}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.twentyone}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.twentytwo}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.twentythree}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.twentyfour}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.twentyfive}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.twentysix}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ProductCard
                className="mt_30"
                productimg={Assets.twentyseven}
                price="$122"
                originalprice="$154"
                desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
              />
            </SwiperSlide>
          </Swiper>
          </div>
          {/* Most Popular Swiper Responsive */}

        </Container>
      </div>
    </>
  );
};

export default Home;
