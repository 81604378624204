import { React, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import {
  ArrowRight,
  BackArrow,
  DeleteIcon,
  PlusIcon,
  ProductRatingStar,
  StarReviewIcon,
} from "../../constant/svg";
import ProductSlider from "../../components/ProductSlider/ProductSlider";
import InStock from "../../components/ProjectStatus/InStock";
import RatingReview from "../../components/RatingReview/RatingReview";
import DeleteModal from "../../components/Modals/DeleteModal";
import EditProductModal from "../../components/Modals/EditProductModal";
import { NavLink } from "react-router-dom";

const ProductDetails = (props) => {
  const [deletemodalShow, setDeleteModalShow] = useState(false);
  const [editproductmodalshow, setEditProductModalShow] = useState(false);


  return (
    <>
          <div className="d-flex align-items-center">
        <NavLink to={props.backpage}>
          <BackArrow />
        </NavLink>
        <div className="main-subheading">Product Details</div>
      </div>
      <div className="product-bg">
        <Row>
          <Col md={4}>
            <ProductSlider
            mainimg1={Assets.ProductMain}
            mainimg2={Assets.ProductMain}
            mainimg3={Assets.ProductMain}
            mainimg4={Assets.ProductMain}
            mainimg5={Assets.ProductMain}
            mainimg6={Assets.ProductMain}
            mainimg7={Assets.ProductMain}
            mainimg8={Assets.ProductMain}
            mainimg9={Assets.ProductMain}
            mainimg10={Assets.ProductMain}
            subimg1={Assets.productone}
            subimg2={Assets.producttwo}
            subimg3={Assets.productthree}
            subimg4={Assets.productfour}
            subimg5={Assets.productone}
            subimg6={Assets.producttwo}
            subimg7={Assets.productthree}
            subimg8={Assets.productfour}
            subimg9={Assets.producttwo}
            subimg10={Assets.productthree}

             />
          </Col>
          <Col md={8}>
            <div className="product-data">
              <div className="d-block d-sm-flex justify-content-between">
                <div className="d-flex">
                  <div className="product-title">Vintage Wrangler jeans</div>
                  <InStock />
                </div>
                <div className="d-flex mt-3 mb-3 m-sm-0">
                  <div>
                    <Button
                      onClick={() => {
                        setEditProductModalShow(true);
                      }}
                      className="edit-product-btn"
                    >
                      Edit Product
                    </Button>
                  </div>
                  <div
                    className="plus-btn"
                    onClick={() => {
                      setDeleteModalShow(true);
                    }}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </div>
              {/* product price section starts here */}
              <div className="d-flex">
                <div className="product-discount-price">$ 150.45</div>
                <div className="product-original-price">
                  <strike>$150.45</strike>
                </div>
              </div>
              {/* product price section ends here */}

              {/* product review section starts here */}
              <div className="review-section d-flex align-items-center">
                <StarReviewIcon />
                <div className="rating-reviews">4.9 (2130 reviews)</div>
              </div>
              {/* producr review section ends here */}

              {/* Tags section */}
              {/* <div className="tags-section d-flex align-items-center">
                <div className="tags">Women</div>
                <div className="tags">Shirt</div>
                <div className="tags">Crop top</div>
                <div className="tags">Red</div>
                <div className="tags">Party dress</div>
              </div> */}
              {/* Tags section ends here */}

              {/* made in section starts here */}
                  {/* made in section starts here */}
                  <div className="made-in-section d-flex align-items-center justify-content-between">
                    <div>
                      <div className="heading">Origin</div>
                      <div>USA</div>
                    </div>
                    <div className="seperator-line"></div>
                    <div>
                      <div className="heading">Ratings</div>
                      <div className="d-flex align-items-center">
                        <ProductRatingStar />
                        <div className="buyer-product-rating">
                          4.0<span className="reviews-num">(225)</span>
                        </div>
                      </div>
                    </div>
                    <div className="seperator-line"></div>
                    <div>
                      <div className="heading">No. of Orders</div>
                      <div>360</div>
                    </div>
                  </div>
                  {/* made in section ends here */}
              {/* made in section ends here */}


              {/* Product Details starts here */}
                <div className="productinfo mt-3">
                <div className="other-info">Product Details</div>
                <ul>
                  <li>
                  Size(s): <span>XS, S, M, L, XL XXL</span>
                  </li>
                  <li>
                  Color(s): <span>Black, Cyan, Blue, Green, Pink</span>
                  </li>
                  <li>
                  Material: <span>Cotton 99%</span>
                  </li>
                  <li>
                  SKU: <span>CJ26598853</span>
                  </li>
                </ul>
              </div>
              {/* Product Details ends here */}

              {/* Product description starts here */}
              <div className="productinfo mt-3">
                <div className="other-info">Shipping</div>
                <ul>
                <li>
                Shipping Charges: <span>$10 worldwide</span>
                  </li>
                  <li>
                  Shipping from: <span>Texas</span>
                  </li>
                  <li>
                  Delivery: <span>4-5 business days</span>
                  </li>
                </ul>
              </div>
              {/* Product description ends here */}

              {/* Product other info css starts here */}
              <div className="product-description">
                <div className="description-heading">Exchange/Return</div>
                <ul className="details-description-width" style={{listStyle:"none"}}>
                <li>

                This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. He who searches for 
              meaning here will be sorely disappointed. 
                  </li>
                </ul>
              </div>
              {/* Product other info css ends here */}
            </div>
          </Col>
        </Row>
      </div>

      <Row>
        <Col md={12}>
          {/* Rating And Reviews Section Starts Here */}
          <RatingReview />
          {/* Rating And Reviews Section Ends Here */}
        </Col>
      </Row>
      <DeleteModal
        heading="Delete Product"
        type="delete this product"
        btntext="Delete"
        link="/products/product-details"
        display="onClick={()=>{props.onHide}}"
        show={deletemodalShow}
        onHide={() => setDeleteModalShow(false)}
      />
      <EditProductModal
        heading="delete"
        type="frfrfrfrfr"
        show={editproductmodalshow}
        onHide={() => setEditProductModalShow(false)}
      />
    </>
  );
};

export default ProductDetails;
