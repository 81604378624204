import { React, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Assets } from "../../constant/images";
import { Col, InputGroup, Row } from "react-bootstrap";
import {
  AllHome,
  Basket,
  BuyerDropdown,
  BuyerDropdownProfile,
  CategoryCrossIcon,
  ChatMessageIcon,
  DropdownIcon,
  Heart,
  MegamenuRightIcon,
  MenuIcon,
  NotificationBuyerIcon,
  SearchIcon,
} from "../../constant/svg";
import { NavLink } from "react-router-dom";
import Dropdown from "react-multilevel-dropdown";
import NotificationModal from "../Modals/NotificationModal";

const DropdownMenu = () => {
  return (
    <div>
                            <div className="test">
                      <div className="dropdown-main-heading">
                        Jewellery & Accessories
                      </div>
                      <div className="new-test">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="dropdown-menu-option">Necklaces</div>
                          <div>
                            <MegamenuRightIcon />
                          </div>
                        </div>
                        <div className="new-test-result">
                          <Row>
                            <Col xxl={12}>
                              <div>
                                <div className="dropdown-main-heading p-0">
                                  Necklaces
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Pendants
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Chokers
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Charm Necklace
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Crystal Necklace
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Monogram & Name Necklace
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Beaded Necklace
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Chains
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Tassel Necklace
                                  </NavLink>
                                </div>
                                <NavLink to="product-listing">
                                  <div className="dropdown-all-home d-flex">
                                    <div className="dropdown-all-home-text">
                                      All Home
                                    </div>
                                    <div>
                                      <AllHome />
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="new-test">
                        <div className="d-flex align-items-center justify-content-between ">
                          <div className="dropdown-menu-option">Rings</div>
                          <div>
                            <MegamenuRightIcon />
                          </div>
                        </div>
                        <div className="new-test-result">
                          <Row>
                            <Col xxl={12}>
                              <div>
                                <div className="dropdown-main-heading p-0">
                                  Necklaces
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Pendants
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Chokers
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Charm Necklace
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Crystal Necklace
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Monogram & Name Necklace
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Beaded Necklace
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Chains
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Tassel Necklace
                                  </NavLink>
                                </div>
                                <NavLink to="/product-listing">
                                  <div className="dropdown-all-home d-flex">
                                    <div className="dropdown-all-home-text">
                                      All Home
                                    </div>
                                    <div>
                                      <AllHome />
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="new-test">
                        <div className="d-flex align-items-center justify-content-between ">
                          <div className="dropdown-menu-option">Earrings</div>
                          <div>
                            <MegamenuRightIcon />
                          </div>
                        </div>
                        <div className="new-test-result">
                          <Row>
                            <Col xxl={12}>
                              <div>
                                <div className="dropdown-main-heading p-0">
                                  Necklaces
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Pendants
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Chokers
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Charm Necklace
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Crystal Necklace
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Monogram & Name Necklace
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Beaded Necklace
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Chains
                                  </NavLink>
                                </div>
                                <div className="dropdown-menu-sub-option">
                                  <NavLink to="/product-listing">
                                    Tassel Necklace
                                  </NavLink>
                                </div>
                                <NavLink to="/product-listing">
                                  <div className="dropdown-all-home d-flex">
                                    <div className="dropdown-all-home-text">
                                      All Home
                                    </div>
                                    <div>
                                      <AllHome />
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
    </div>
  )
}

export default DropdownMenu