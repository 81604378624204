import React from "react";
import { FloatingLabel, Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { ModalCrossIcon } from "../../utils/svg";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import signupupload from "../../assets/images/Auth/uploadcategoryimg.png";
import AddCategoryComponent from "./AddCategoryComponent";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";
const AddCategoryModal = (props) => {
  const [displaycomponent, setDisplayComponent] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const navigate = useNavigate();
  const onSubmit = (data) => {
    // props.setShowCategoryModal(false);
    // props.setEditCategoryModal(false);
    props.onHide();
    setDisplayComponent(false);
    // navigate("/otpverify");
  };
  return (
    <>
      <Modal
        {...props}
        onHide={() => {
          // props.setShowCategoryModal(false);
          // props.setEditCategoryModal(false);
          props.onHide();
          setDisplayComponent(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="add-category-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4> {props.title}</h4>
          </Modal.Title>
          <div className="close-div">
            <Button
              onClick={() => {
                // props.setShowCategoryModal(false);
                // props.setEditCategoryModal(false);
                props.onHide();
                setDisplayComponent(false);
              }}
            >
              <ModalCrossIcon />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="signup-form">
              <FloatingLabel controlId="floatingInput" label="Product Title">
                <Form.Control
                  type="text"
                  maxLength={VALIDATIONS.PRODUCT_TITLE_MAX}
                  {...register("product_title", {
                    maxLength: {
                      value: VALIDATIONS.PRODUCT_TITLE_MAX,
                      message: VALIDATIONS_TEXT.PRODUCT_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.PRODUCT_TITLE_REQUIRED,
                    },
                  })}
                />
                {errors.product_title && (
                  <small className="text-red error_from">
                    {errors.product_title.message}
                  </small>
                )}
              </FloatingLabel>
            </div>
            <div className="checkout-signup">
              {/* <Form>
                <Form.Check
                  label="Create Under Parent"
                  name="capital"
                  for="myCheck"
                  type="checkbox"
                  id="myCheck"
                  // onClick={() => setDisplayComponent(!displaycomponent)}
                />
              </Form> */}
              {/* {displaycomponent === true ? <AddCategoryComponent title="Add Category" /> : null} */}
              <AddCategoryComponent title="Add Category" />
            </div>
      
          <div className="submit-div">
            <Button type="submit">
              {props.btntitle}
            </Button>
          </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCategoryModal;
