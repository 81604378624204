import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Assets } from "../../constant/images";
import { InputGroup } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import {
  Basket,
  CartIcon,
  DropdownIcon,
  Heart,
  HeartIcon,
  MenuIcon,
  SearchIcon,
} from "../../constant/svg";
import { NavLink } from "react-router-dom";
import { useState } from "react";

const ProductListingCard = ({ data, listview }) => {

  return (
    <Col
    className={listview  ? 'col-12' : 'col-lg-4 col-md-6 col-sm-12 col-12'}
    >
      <NavLink to="/buyer-product-details">
      <div className={listview === true  ? 'bg-salmon mb_30' : 'product-card-bg mb_30'}>
        <div className="img-container">
          <img className="w-100" src={data.productimg} />
          <div className="bottom-left">
            <div className="price-tag">
              <div className="price">{data.price}</div>
              <strike>
                <div className="original-price">${data.originalprice}</div>
              </strike>
            </div>
          </div>
          <div className="top-right">
            <div className="icon-container">
              <HeartIcon />
            </div>
          </div>

          <div className="bottom-right">
            <div className="icon-container">
              <CartIcon />
            </div>
          </div>
        </div>
        <div className="product-desc">{data.desc}</div>
      </div>
      </NavLink>
    </Col>
  );
};

export default ProductListingCard;
