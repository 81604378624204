import React from 'react'
import { Container } from 'react-bootstrap'
import { Assets } from '../../constant/images'

const CartEmpty = () => {
  return (
    <div className="main-section">
        <Container>
            <div className='cart-empty-section'>
                <div className='empty-msg'>
                    <div className="d-flex">
                        <div>
                            <img src={Assets.Cartempty}/>
                        </div>
                        <div className="msg-text">
                            Buy confidently with afrotierre Purchase Protection program for buyers, get a full refund in the rare case your item doesn't arrive, arrives damaged, or isn't as described. See eligibility
                        </div>
                    </div>
                </div>
                <div className="text-center cart-bag">
                        <div><img src={Assets.EmptyBag}/></div>
                        <div className='empty-bag-text'>Your bag is empty</div>
                </div>
            </div>
        </Container>
    </div>
  )
}

export default CartEmpty