import React, { useState } from "react";
import { Button, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import CheckoutProducts from "../../components/Checkout/CheckoutProducts";
import PaymentCard from "../../components/Checkout/PaymentCard";
import CancelOrderModal from "../../components/Modals/CancelOrderModal";
import { Assets } from "../../constant/images";
import { AddNewCard } from "../../constant/svg";




const CheckoutLayout = (props) => {
  const [cancelModalShow,setCancelModalShow]=useState(false)
  return (
    <div className="checkout">
      <Container>
        <div className="checkout-main-heading">{props.mainheading}</div>
        <Row>
          <Col md={5} lg={6}>
            {props.children}
          </Col>
          <Col md={7} lg={{span: 5, offset: 1}}>
            <div className="total-items-container ">
              <div className="items-heading">Items (2) Total</div>
              <div className="subhr">
                <hr />
              </div>
              <CheckoutProducts
                checkouttitle="DiW_ Shop 24/7"
                checkoutprice="USD $48"
                qtyprice="$150.45"
                qty="1"
                cartproductimg={Assets.CartProduct2}
              />
              <CheckoutProducts
                checkouttitle="DiW_ Shop 24/7"
                checkoutprice="USD $48"
                qtyprice="$150.45"
                qty="1"
                cartproductimg={Assets.CartProduct1}
              />
              <div className="total-item-checkout-section">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="item">Sub Total :</div>
                  <div className="total">$140</div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt_15">
                  <div className="item">Shipping:</div>
                  <div className="total">Free</div>
                </div>
                <div className="d-flex justify-content-end mt_15 est-time">
                  (Est time. 14-16 Nov)
                </div>
                <div className="totalhr">
                  <hr />
                </div>
                <div className="d-flex align-items-center justify-content-between mt_15">
                  <div className="item" style={{ fontWeight: 600 }}>
                    Total Amount:
                  </div>
                  <div className="total" style={{ fontWeight: 600 }}>
                    $140.45
                  </div>
                </div>
                <div>
                  <NavLink to={props.linkk}><Button className="proceed-btn">{props.btn1txt}</Button></NavLink>
                </div>
                <div  onClick={() => setCancelModalShow(true)}>
                  <NavLink to={props.link2}><Button className="continue-btn">{props.btn2txt}</Button></NavLink>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <CancelOrderModal
          show={cancelModalShow}
          onHide={() => setCancelModalShow(false)}
        />
    </div>
  );
};

export default CheckoutLayout;
