export const VALIDATIONS = {
    NAME: 50,
    STORE_NAME: 50,
    COUNTRY_NAME: 50,
    CITY_NAME: 50,
    CITY:20,
    PHONE_MIN: 10,
    PHONE_MAX: 16,
    BIO_MAX: 255,
    EMAIL: 255,
    PASSWORD_MIN: 8,
    PASSWORD_MAX: 32,
    VERIFICATION_CODE: 4,
    CARD_NUMBER: 16,
    CVV: 3,
    ADDRESS_MAX: 200,
    STATE: 50,
    ZIP_CODE: 12,
    PRODUCT_TITLE_MAX: 40,
    SUBJECT_LENGTH_MAX: 100,
  };
  
  export const VALIDATIONS_TEXT = {
    NAME_REQUIRED: "Full name is required",
    SUBJECT_REQUIRED: "Subject is required",
    FIRST_NAME_REQUIRED: "First name is required",
    LAST_NAME_REQUIRED: "Last name is required",
    STORE_NAME_REQUIRED: "Store name is required",
    COUNTRY_NAME_REQUIRED: "Country name is required",
    CITY_NAME_REQUIRED: "City name is required",
    CITY_REQUIRED: "City is required",
    PRODUCT_TITLE_REQUIRED: "Product title is required",
    ZIP_REQUIRED: "Zip is required",
    ADDRESS_REQUIRED: "Address is required",
    ZIP_CODE_REQUIRED: "Zip Code is required",
    EMAIL_REQUIRED: "Email is required",
    EMAIL_FORMAT: "Enter valid email address",
    EXCLUDE_SPECIAL_CHARACTERS: "Enter full name containing alphabets only",
    EXCLUDE_SPECIAL_CHARACTERS_STATE: "Enter state name containing alphabets only",
    EXCLUDE_SPECIAL_CHARACTERS_CITY: "Enter city name containing alphabets only",
    EXCLUDE_SPECIAL_CHARACTERS_ZIP: "Enter zip  code containing numbers only",
    PASSWORD_FORMAT: "At least one uppercase, one lowercase and one special character are allowed",
    PASSWORD_REQUIRED: "Password is required",
    CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
    PHONE_REQUIRED: "Contact number is required",
    ADDRESS_REQUIRED: "Address is required",
    STATE_REQUIRED: "State is required",
    CARD_NUMBER_REQUIRED: "Card number is required",
    INVALID_STATE_FORMAT: "Enter state containing alphabets only",
    INVALID_FORMAT_FIRST_NAME: "Enter first name containing alphabets only",
    INVALID_FORMAT_LAST_NAME: "Enter last name containing alphabets only",
    CARD_NUMBER_REQUIRED: "Group Code is required",
  
    NAME_MAX: "Maximum length is " + VALIDATIONS.NAME,
    ZIP_MAX: "Maximum length is " + VALIDATIONS.ZIP_CODE,
    PRODUCT_MAX: "Maximum length is " + VALIDATIONS.PRODUCT_TITLE_MAX,
    STORE_MAX: "Maximum length is " + VALIDATIONS.STORE_NAME,
    STATE_MAX: "Maximum length is " + VALIDATIONS.STATE,
    CITY_MAX: "Maximum length is " + VALIDATIONS.CITY_NAME,
    EMAIL_MAX: "Maximum length is " + VALIDATIONS.EMAIL,
    PASSWORD_MIN: "Minimum length is " + VALIDATIONS.PASSWORD_MIN,
    PASSWORD_MAX: "Maximum length is " + VALIDATIONS.PASSWORD_MAX,
    SUBJECT_MAX: "Maximum length is " + VALIDATIONS.SUBJECT_LENGTH_MAX,
    CARD_NUMBER_MIN: "Minimum length is " + VALIDATIONS.CARD_NUMBER,
    CARD_NUMBER_MAX: "Maximum length is " + VALIDATIONS.CARD_NUMBER,
    PHONE_MIN: "Minimum length is " + VALIDATIONS.PHONE_MIN,
    PHONE_MAX: "Maximum length is " + VALIDATIONS.PHONE_MAX,
    BIO_MAX: "Maximum length is " + VALIDATIONS.BIO_MAX,
    CVV_MIN: "Minimum length is " + VALIDATIONS.CVV,
    CVV_MAX: "Maximum length is " + VALIDATIONS.CVV,
    ADDRESS_MAX: "Maximum length is " + VALIDATIONS.ADDRESS_MAX,
    VERIFICATION_CODE: "Length is " + VALIDATIONS.VERIFICATION_CODE,
    INVALID_FORMAT: "Invalid format",
    INVALID_PHONE_FORMAT: "Enter correct number containing 11 digits",
  };
  
  export const SPECIAL_CHARACTER_ARRAY = [
    "~",
    "`",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "-",
    "_",
    "+",
    "=",
    "{",
    "}",
    "[",
    "]",
    ";",
    ":",
    "'",
    '"',
    "\\",
    "|",
    "<",
    ",",
    ">",
    ".",
    "?",
    "/",
  ];
  
  export const SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS = [
    "~",
    "`",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "_",
    "+",
    "=",
    "{",
    "}",
    "[",
    "]",
    ";",
    ":",
    "'",
    '"',
    "\\",
    "|",
    "<",
    ">",
    ".",
    "?",
    "/",
  ];
  
  export const CHARACTER_ARRAY_FOR_NUMBER_INPUT = ["e", "E", "-", "+", "."];
  
  export const EXCLUDE_NUMBER_INPUT = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  
  
  export const EXCLUDE_ALPHABETS_SPECIAL_CHAR = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "~",
    "`",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "_",

    "=",
    "{",
    "}",
    "[",
    "]",
    ";",
    ":",
    "'",
    '"',
    "\\",
    "|",
    "<",
    ",",
    ">",
    ".",
    "?",
    "/",
  ];
  
  export const SPECIAL_CHARACTER_NUMBER_ARRAY = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "~",
    "`",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "*",
    "(",
    ")",
    "-",
    "_",
    "+",
    "=",
    "{",
    "}",
    "[",
    "]",
    ";",
    ":",
    "'",
    '"',
    "\\",
    "|",
    "<",
    ",",
    ">",
    ".",
    "?",
    "/",
  ];

export const STATUS ={
  confirmed: "confirmed",
  pending: "pending",
  completed: "completed",
  cancelled: "cancelled"
}
//     "A",
//     "B",
//     "C",
//     "D",
//     "E",
//     "F",
//     "G",
//     "H",
//     "I",
//     "J",
//     "K",
//     "L",
//     "M",
//     "N",
//     "O",
//     "P",
//     "Q",
//     "R",
//     "S",
//     "T",
//     "U",
//     "V",
//     "W",
//     "X",
//     "Y",
//     "Z",
//     "~",
//     "`",
//     "!",
//     "@",
//     "#",
//     "$",
//     "%",
//     "^",
//     "&",
//     "*",
//     "(",
//     ")",
//     "-",
//     "_",
//     "+",
//     "=",
//     "{",
//     "}",
//     "[",
//     "]",
//     ";",
//     ":",
//     "'",
//     '"',
//     "\\",
//     "|",
//     "<",
//     ",",
//     ">",
//     ".",
//     "?",
//     "/",
//   ];