import { React, useState } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  InputGroup,
  DropdownButton,
} from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import {
  ArrowRight,
  BackPage,
  HiddenPagesNo,
  NextestPage,
  NextPage,
  SearchIconTopBar,
  StarProductRating,
  UserIcon,
} from "../../constant/svg";
import { NavLink } from "react-router-dom";
import InStock from "../../components/ProjectStatus/InStock";
import OutStock from "../../components/ProjectStatus/OutStock";
import DeleteModal from "../../components/Modals/DeleteModal";
import EditProductModal from "../../components/Modals/EditProductModal";
import QuantityModal from "../../components/Modals/UpdateQuantityMmodal";
import Select from "react-select";


const Inventory = (props) => {
  const [quantitymodalshow, setQuantityModalShow] = useState(false);
  const [deletemodalShow, setDeleteModalShow] = useState(false);


    const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: "20px",
      textAlign: "left",
      paddingLeft: "10px",
      color: "red",
      fontSize: "14px",
      fontColor: "red",
      boxShadow: "none",
      color: state.isSelected ? "red" : "yellow",
      border: state.isFocused ? "1px solid #dbbb51" : "1px solid #ccc",
      "&:hover": {
        borderColor: state.isFocused ? "#dbbb51" : "#dbbb51",
      },
    }),
    placeholder: (defaultStyles) => {
      return {
          ...defaultStyles,
          color: '#707581',
          paddingLeft:"0",
      }
  },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: "pointer",
        backgroundColor: isDisabled ? "red" : "white",

        color: "#707581",
        fontSize: "14px",
        boxShadow: "none",
        textAlign: "left",
        ":hover": {
          backgroundColor: "#F4F4F4",
          color: "#dbbb51",
        },
      };
    },
  };

  const columns = [
    {
      field: "UserId",
      headerName: "Product Title",
      width: 240,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <NavLink to={props.nextpagelink}>
            <div className="d-flex align-items-center mt-2">
              <img className="img-fluid" src={params.row.image} />
              <div className=" product-table-data">
                <div className="product-name">{params.row.productname}</div>
                <div className="product-no">{params.row.productno}</div>
              </div>
            </div>
          </NavLink>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 270,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <NavLink to={props.nextpagelink}>
          <div className="start-date">{params.row.quantity}</div>
          </NavLink>
          )
      },
    },
    {
      field: "status",
      headerName: "Product Status",
      width: 270,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <NavLink to={props.nextpagelink}>
          <div>{params.row.status}</div>
          </NavLink>
          )
      },
    },
    {
      headerName: "Action",
      width: 220,
      id: "action",
      name: "Action",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="user-button">
            <DropdownButton id="dropdown-basic-button" title={<UserIcon />}>
              <Dropdown.Item
                // onClick={() => setShowEditNew(true)}
                onClick={() => {
                  setQuantityModalShow(true);
                }}
              >
                Update
              </Dropdown.Item>
              <Dropdown.Item
                // onClick={() => setShowDelete(true)}
                onClick={() => {
                  setDeleteModalShow(true);
                }}
              >
                Delete
              </Dropdown.Item>
            </DropdownButton>
          </div>
        );
      },
    },
  ];

  const rows = [
    {
      id: 1,
      image: Assets.Tableavatarone,
      productname: "Home sweet home sign, rustic wood sign, wood decoration",
      productno: "CJ26598853",
      quantity: "41",
      status: <InStock />,
    },

    {
      id: 2,
      image: Assets.Tableavatartwo,
      productname: "Home sweet home sign, rustic wood sign, wood decoration",
      productno: "CJ26598853",
      UserId: "02",
      quantity: "33",
      status: <OutStock />,
    },

    {
      id: 3,
      image: Assets.Tableavatarthree,
      productname: "Home sweet home sign, rustic wood sign, wood decoration",
      productno: "CJ26598853",
      UserId: "03",
      quantity: "22",
      status: <OutStock />,
    },
    {
      id: 4,
      image: Assets.Tableavatarfour,
      productname: "Home sweet home sign, rustic wood sign, wood decoration",
      productno: "CJ26598853",
      UserId: "04",
      quantity: "67",
      status: <OutStock />,
    },
    {
      id: 5,
      image: Assets.Tableavatarone,
      productname: "Home sweet home sign, rustic wood sign, wood decoration",
      productno: "CJ26598853",
      UserId: "05",
      quantity: "87",
      status: <InStock />,
    },
    {
      id: 6,
      image: Assets.Tableavatartwo,
      productname: "Home sweet home sign, rustic wood sign, wood decoration",
      productno: "CJ26598853",
      UserId: "06",
      quantity: "22",
      status: <InStock />,
    },
    {
      id: 7,
      image: Assets.Tableavatarthree,
      productname: "Home sweet home sign, rustic wood sign, wood decoration",
      productno: "CJ26598853",
      UserId: "07",
      quantity: "39",
      status: <InStock />,
    },
    {
      id: 8,
      image: Assets.Tableavatarfour,
      productname: "Home sweet home sign, rustic wood sign, wood decoration",
      productno: "CJ26598853",
      UserId: "08",
      quantity: "24",
      status: <InStock />,
    },
    {
      id: 9,
      image: Assets.Tableavatarone,
      productname: "Home sweet home sign, rustic wood sign, wood decoration",
      productno: "CJ26598853",
      UserId: "09",
      quantity: "12",
      status: <OutStock />,
    },
  ];

  return (
    <>
      <div className="main-title-heading">Inventory Management</div>
      <div className="order-table-container">
        <div className="d-block d-md-flex justify-content-between">
          <div className="table-search-box">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <NavLink to="/search">
                  <SearchIconTopBar />
                </NavLink>
              </InputGroup.Text>
              <Form.Control
                placeholder="Search Products"
                aria-label="Search"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </div>
          <div className="d-block d-sm-flex">
          <div className="order-dropdown">
              <Select
                className=" dropdown-custom-btn normal w-100"
                classNamePrefix="afrorierre"
                isMenuOpen={true}
                options={[
                  { value: "chocolate", label: "All" },
                  { value: "strawberry", label: "New" },
                  { value: "vanilla", label: "Last Month" },
                ]}
                placeholder="All"
                name="reactSelectName"
                // menuIsOpen={true}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={colourStyles}
              />
            </div>
          </div>
        </div>
        <div style={{ height: 560, width: "100%" }}>
          <DataGrid
            className="datagrid-table"
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[7]}
            checkboxSelection
          />
        </div>
        <div className="pagination-border d-flex justify-content-end">
          <div className="seller-paginations d-flex">
            <div className="page-box"><BackPage/></div>
            <div className="page-box active">1</div>
            <div className="page-box"><HiddenPagesNo/></div>
            <div className="page-box"><NextPage/></div>
            <div className="page-box"><NextestPage/></div>
          </div>
        </div>
      </div>

      <DeleteModal
        heading="Delete Product"
        type="delete this product"
        btntext="Delete"
        link="/products/product-details"
        display="onClick={()=>{props.onHide}}"
        show={deletemodalShow}
        onHide={() => setDeleteModalShow(false)}
      />

      <QuantityModal
        show={quantitymodalshow}
        onHide={() => setQuantityModalShow(false)}
      />
    </>
  );
};

export default Inventory;
