import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { CrossIcon } from "../../constant/svg";
import { NotiDropDown, SeenIcon } from "../../utils/svg";

const NotificationModal = (props) => {
  return (
    <div>
      {/* Notification Modal Starts Here */}
      <Modal
        className="notification-modal"
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-center"
      >
        <div className="notification-popup">
          <div className="d-flex align-items-center justify-content-between">
            <div className="notification-heading">Notifications</div>
            <div className="cross-bg cursor-link" onClick={props.onHide}>
              <CrossIcon />
            </div>
          </div>
          <div className="main-notification-panel">
          <div className="notification-box mt_10">
            <div className="d-flex">
                <div className="unseen mt-1"></div>
                <div className="ps-2">
                <div className="order-status">Order Completed</div>
                <div className="order-notif">Order #23456 has been delivered successfully</div>
                <div className="notif-delivery-time">4 hours ago</div>
                </div>
            </div>
            <hr/>

          </div>
          <div className="notification-box mt_10">
            <div className="d-flex">
                <div className="unseen mt-1"></div>
                <div className="ps-2">
                <div className="order-status">Order Completed</div>
                <div className="order-notif">Order #23456 has been delivered successfully</div>
                <div className="notif-delivery-time">4 hours ago</div>
                </div>
            </div>
            <hr/>

          </div>
          <div className="notification-box mt_10">
            <div className="d-flex">
                <div className="unseen mt-1"></div>
                <div className="ps-2">
                <div className="order-status">Order Completed</div>
                <div className="order-notif">Order #23456 has been delivered successfully</div>
                <div className="notif-delivery-time">4 hours ago</div>
                </div>
            </div>
            <hr/>

          </div>
          <div className="mt_20 view-all text-center cursor-link">View All (20) <NotiDropDown/></div>
          <div className="main-seen-clear mt_20">
            <div className="main-seen-div">
              <SeenIcon/> <span>Seen</span>
            </div>
            <div className="main-clear-div">
              <Button>Clear All</Button>
            </div>
          </div>
          <div className="notification-box mt_10 ml_7">
            <div className="d-flex">
                <div className="seen mt-1"></div>
                <div className="ps-2">
                <div className="order-status">Order Completed</div>
                <div className="order-notif">Order #23456 has been delivered successfully</div>
                <div className="notif-delivery-time">4 hours ago</div>
                </div>
            </div>
            <hr/>

          </div>
          <div className="notification-box mt_10 ml_7">
            <div className="d-flex">
                <div className="seen mt-1"></div>
                <div className="ps-2">
                <div className="order-status">Order Completed</div>
                <div className="order-notif">Order #23456 has been delivered successfully</div>
                <div className="notif-delivery-time">4 hours ago</div>
                </div>
            </div>
            <hr/>

          </div>
          <div className="notification-box mt_10 ml_7">
            <div className="d-flex">
                <div className="seen mt-1"></div>
                <div className="ps-2">
                <div className="order-status">Order Completed</div>
                <div className="order-notif">Order #23456 has been delivered successfully</div>
                <div className="notif-delivery-time">4 hours ago</div>
                </div>
            </div>
            <hr/>

          </div>
          </div>

        </div>
      </Modal>
      {/* Notification Modal Ends Here */}
    </div>
  );
};

export default NotificationModal;
