function AccountDetailsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F6F6FA" />
      <path
        d="M12.0803 13.02C12.067 13.02 12.047 13.02 12.0336 13.02C12.0136 13.02 11.987 13.02 11.967 13.02C10.4536 12.9733 9.32031 11.7933 9.32031 10.34C9.32031 8.85999 10.527 7.65332 12.007 7.65332C13.487 7.65332 14.6936 8.85999 14.6936 10.34C14.687 11.8 13.547 12.9733 12.1003 13.02C12.087 13.02 12.087 13.02 12.0803 13.02ZM12.0003 8.64665C11.067 8.64665 10.3136 9.40665 10.3136 10.3333C10.3136 11.2467 11.027 11.9867 11.9336 12.02C11.9536 12.0133 12.0203 12.0133 12.087 12.02C12.9803 11.9733 13.6803 11.24 13.687 10.3333C13.687 9.40665 12.9336 8.64665 12.0003 8.64665Z"
        fill="#77778D"
      />
      <path
        d="M11.9992 19.1669C10.2059 19.1669 8.49257 18.5002 7.16591 17.2869C7.04591 17.1802 6.99257 17.0202 7.00591 16.8669C7.09257 16.0735 7.58591 15.3335 8.40591 14.7869C10.3926 13.4669 13.6126 13.4669 15.5926 14.7869C16.4126 15.3402 16.9059 16.0735 16.9926 16.8669C17.0126 17.0269 16.9526 17.1802 16.8326 17.2869C15.5059 18.5002 13.7926 19.1669 11.9992 19.1669ZM8.05257 16.7335C9.15924 17.6602 10.5526 18.1669 11.9992 18.1669C13.4459 18.1669 14.8392 17.6602 15.9459 16.7335C15.8259 16.3269 15.5059 15.9335 15.0326 15.6135C13.3926 14.5202 10.6126 14.5202 8.95924 15.6135C8.48591 15.9335 8.17257 16.3269 8.05257 16.7335Z"
        fill="#77778D"
      />
      <path
        d="M12.0007 19.1663C8.04732 19.1663 4.83398 15.953 4.83398 11.9997C4.83398 8.04634 8.04732 4.83301 12.0007 4.83301C15.954 4.83301 19.1673 8.04634 19.1673 11.9997C19.1673 15.953 15.954 19.1663 12.0007 19.1663ZM12.0007 5.83301C8.60065 5.83301 5.83398 8.59967 5.83398 11.9997C5.83398 15.3997 8.60065 18.1663 12.0007 18.1663C15.4007 18.1663 18.1673 15.3997 18.1673 11.9997C18.1673 8.59967 15.4007 5.83301 12.0007 5.83301Z"
        fill="#77778D"
      />
    </svg>
  );
}

function AddressIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F6F6FA" />
      <path
        d="M12.0019 13.4463C10.5819 13.4463 9.42188 12.293 9.42188 10.8663C9.42188 9.43964 10.5819 8.29297 12.0019 8.29297C13.4219 8.29297 14.5819 9.4463 14.5819 10.873C14.5819 12.2996 13.4219 13.4463 12.0019 13.4463ZM12.0019 9.29297C11.1352 9.29297 10.4219 9.99964 10.4219 10.873C10.4219 11.7463 11.1285 12.453 12.0019 12.453C12.8752 12.453 13.5819 11.7463 13.5819 10.873C13.5819 9.99964 12.8685 9.29297 12.0019 9.29297Z"
        fill="#77778D"
      />
      <path
        d="M12.0011 19.173C11.0144 19.173 10.0211 18.7997 9.24776 18.0597C7.28109 16.1663 5.10776 13.1463 5.92776 9.55301C6.66776 6.29301 9.51443 4.83301 12.0011 4.83301C12.0011 4.83301 12.0011 4.83301 12.0078 4.83301C14.4944 4.83301 17.3411 6.29301 18.0811 9.55967C18.8944 13.153 16.7211 16.1663 14.7544 18.0597C13.9811 18.7997 12.9878 19.173 12.0011 19.173ZM12.0011 5.83301C10.0611 5.83301 7.56776 6.86634 6.90776 9.77301C6.18776 12.913 8.16109 15.6197 9.94776 17.333C11.1011 18.4463 12.9078 18.4463 14.0611 17.333C15.8411 15.6197 17.8144 12.913 17.1078 9.77301C16.4411 6.86634 13.9411 5.83301 12.0011 5.83301Z"
        fill="#77778D"
      />
    </svg>
  );
}

function MyOrdersIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F6F6FA" />
      <path
        d="M12.0007 12.8669C11.914 12.8669 11.8274 12.8469 11.7474 12.8002L5.86071 9.39356C5.62737 9.25356 5.54071 8.9469 5.68071 8.7069C5.82071 8.4669 6.12737 8.3869 6.36737 8.5269L12.0007 11.7869L17.6007 8.5469C17.8407 8.4069 18.1474 8.49356 18.2874 8.7269C18.4274 8.9669 18.3407 9.27356 18.1074 9.41356L12.2607 12.8002C12.174 12.8402 12.0874 12.8669 12.0007 12.8669Z"
        fill="#77778D"
      />
      <path
        d="M12 18.907C11.7267 18.907 11.5 18.6804 11.5 18.407V12.3604C11.5 12.087 11.7267 11.8604 12 11.8604C12.2733 11.8604 12.5 12.087 12.5 12.3604V18.407C12.5 18.6804 12.2733 18.907 12 18.907Z"
        fill="#77778D"
      />
      <path
        d="M12.0004 19.1663C11.4138 19.1663 10.8338 19.0397 10.3738 18.7863L6.81375 16.8063C5.84708 16.273 5.09375 14.9863 5.09375 13.8797V10.113C5.09375 9.00634 5.84708 7.72634 6.81375 7.18634L10.3738 5.21301C11.2871 4.70634 12.7137 4.70634 13.6271 5.21301L17.1871 7.19301C18.1538 7.72634 18.9071 9.01301 18.9071 10.1197V13.8863C18.9071 14.993 18.1538 16.273 17.1871 16.813L13.6271 18.7863C13.1671 19.0397 12.5871 19.1663 12.0004 19.1663ZM12.0004 5.83301C11.5804 5.83301 11.1671 5.91967 10.8604 6.08634L7.30042 8.06634C6.66042 8.41967 6.09375 9.37967 6.09375 10.113V13.8797C6.09375 14.613 6.66042 15.573 7.30042 15.933L10.8604 17.913C11.4671 18.253 12.5338 18.253 13.1404 17.913L16.7004 15.933C17.3404 15.573 17.9071 14.6197 17.9071 13.8797V10.113C17.9071 9.37967 17.3404 8.41967 16.7004 8.05967L13.1404 6.07967C12.8338 5.91967 12.4204 5.83301 12.0004 5.83301Z"
        fill="#77778D"
      />
    </svg>
  );
}

function PrivacyIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F6F6FA" />
      <path
        d="M11.999 19.1733C11.2723 19.1733 10.5523 18.96 9.98562 18.54L7.11896 16.4C6.35896 15.8333 5.76562 14.6466 5.76562 13.7066V8.74663C5.76562 7.71996 6.51896 6.62663 7.48563 6.26663L10.8123 5.01996C11.4723 4.77329 12.5123 4.77329 13.1723 5.01996L16.499 6.26663C17.4656 6.62663 18.219 7.71996 18.219 8.74663V13.7C18.219 14.6466 17.6256 15.8266 16.8656 16.3933L13.999 18.5333C13.4456 18.96 12.7256 19.1733 11.999 19.1733ZM11.1656 5.95996L7.83896 7.20663C7.27229 7.41996 6.77229 8.13996 6.77229 8.75329V13.7066C6.77229 14.34 7.21896 15.2266 7.71896 15.6L10.5856 17.74C11.3523 18.3133 12.6456 18.3133 13.419 17.74L16.2856 15.6C16.7923 15.22 17.2323 14.34 17.2323 13.7066V8.74663C17.2323 8.13996 16.7323 7.41996 16.1656 7.19996L12.839 5.95329C12.3856 5.79329 11.6123 5.79329 11.1656 5.95996Z"
        fill="#77778D"
      />
      <path
        d="M11.1068 13.4871C10.9802 13.4871 10.8535 13.4404 10.7535 13.3404L9.68016 12.2671C9.48682 12.0737 9.48682 11.7537 9.68016 11.5604C9.87349 11.3671 10.1935 11.3671 10.3868 11.5604L11.1068 12.2804L13.6202 9.76707C13.8135 9.57374 14.1335 9.57374 14.3268 9.76707C14.5202 9.9604 14.5202 10.2804 14.3268 10.4737L11.4602 13.3404C11.3602 13.4404 11.2335 13.4871 11.1068 13.4871Z"
        fill="#77778D"
      />
    </svg>
  );
}

function TermsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F6F6FA" />
      <path
        d="M13.332 8.49967H10.6654C10.0254 8.49967 8.83203 8.49967 8.83203 6.66634C8.83203 4.83301 10.0254 4.83301 10.6654 4.83301H13.332C13.972 4.83301 15.1654 4.83301 15.1654 6.66634C15.1654 7.30634 15.1654 8.49967 13.332 8.49967ZM10.6654 5.83301C10.0054 5.83301 9.83203 5.83301 9.83203 6.66634C9.83203 7.49967 10.0054 7.49967 10.6654 7.49967H13.332C14.1654 7.49967 14.1654 7.32634 14.1654 6.66634C14.1654 5.83301 13.992 5.83301 13.332 5.83301H10.6654Z"
        fill="#77778D"
      />
      <path
        d="M14 19.1669H10C6.25333 19.1669 5.5 17.4469 5.5 14.6669V10.6669C5.5 7.62695 6.6 6.32695 9.30667 6.18695C9.58 6.17361 9.82 6.38028 9.83333 6.66028C9.84667 6.94028 9.63333 7.16695 9.36 7.18028C7.46667 7.28695 6.5 7.85361 6.5 10.6669V14.6669C6.5 17.1336 6.98667 18.1669 10 18.1669H14C17.0133 18.1669 17.5 17.1336 17.5 14.6669V10.6669C17.5 7.85361 16.5333 7.28695 14.64 7.18028C14.3667 7.16695 14.1533 6.92695 14.1667 6.65361C14.18 6.38028 14.42 6.16695 14.6933 6.18028C17.4 6.32695 18.5 7.62695 18.5 10.6603V14.6603C18.5 17.4469 17.7467 19.1669 14 19.1669Z"
        fill="#77778D"
      />
    </svg>
  );
}

function ContactIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F6F6FA" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.9327 6.59636C11.6393 6.46788 12.3633 6.46788 13.0699 6.59636C16.2452 7.17367 18.3878 10.1685 17.9091 13.3601L17.9037 13.3964C16.9365 13.4472 16.168 14.2474 16.168 15.2272V16.5605C16.168 17.5731 16.9888 18.3939 18.0013 18.3939C19.0138 18.3939 19.8346 17.5731 19.8346 16.5605V15.2272C19.8346 14.5333 19.4494 13.9301 18.8815 13.6187L18.898 13.5084C19.4569 9.78258 16.9556 6.28644 13.2488 5.61249C12.4239 5.4625 11.5787 5.4625 10.7538 5.61249C7.04703 6.28644 4.54568 9.78257 5.10456 13.5084L5.12111 13.6187C4.5532 13.9301 4.16797 14.5333 4.16797 15.2272V16.5605C4.16797 17.5731 4.98878 18.3939 6.0013 18.3939C7.01382 18.3939 7.83464 17.5731 7.83464 16.5605V15.2272C7.83464 14.2474 7.06607 13.4472 6.09895 13.3964L6.09349 13.3601C5.61476 10.1685 7.75743 7.17367 10.9327 6.59636ZM5.79637 14.4191C5.86141 14.4027 5.92997 14.3939 6.0013 14.3939C6.46154 14.3939 6.83464 14.767 6.83464 15.2272V16.5605C6.83464 17.0208 6.46154 17.3939 6.0013 17.3939C5.54107 17.3939 5.16797 17.0208 5.16797 16.5605V15.2272C5.16797 14.8382 5.43495 14.5103 5.79637 14.4191ZM17.168 15.2272C17.168 14.767 17.5411 14.3939 18.0013 14.3939C18.0726 14.3939 18.1412 14.4027 18.2062 14.4191C18.5677 14.5103 18.8346 14.8382 18.8346 15.2272V16.5605C18.8346 17.0208 18.4615 17.3939 18.0013 17.3939C17.5411 17.3939 17.168 17.0208 17.168 16.5605V15.2272Z"
        fill="#77778D"
      />
    </svg>
  );
}

function ChangePasswordIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F6F6FA" />
      <path
        d="M16 11.1663C15.7267 11.1663 15.5 10.9397 15.5 10.6663V9.33301C15.5 7.23301 14.9067 5.83301 12 5.83301C9.09333 5.83301 8.5 7.23301 8.5 9.33301V10.6663C8.5 10.9397 8.27333 11.1663 8 11.1663C7.72667 11.1663 7.5 10.9397 7.5 10.6663V9.33301C7.5 7.39967 7.96667 4.83301 12 4.83301C16.0333 4.83301 16.5 7.39967 16.5 9.33301V10.6663C16.5 10.9397 16.2733 11.1663 16 11.1663Z"
        fill="#77778D"
      />
      <path
        d="M15.332 19.167H8.66536C5.72536 19.167 4.83203 18.2737 4.83203 15.3337V14.0003C4.83203 11.0603 5.72536 10.167 8.66536 10.167H15.332C18.272 10.167 19.1654 11.0603 19.1654 14.0003V15.3337C19.1654 18.2737 18.272 19.167 15.332 19.167ZM8.66536 11.167C6.2787 11.167 5.83203 11.6203 5.83203 14.0003V15.3337C5.83203 17.7137 6.2787 18.167 8.66536 18.167H15.332C17.7187 18.167 18.1654 17.7137 18.1654 15.3337V14.0003C18.1654 11.6203 17.7187 11.167 15.332 11.167H8.66536Z"
        fill="#77778D"
      />
      <path
        d="M9.33464 15.3336C9.24797 15.3336 9.1613 15.3136 9.0813 15.2802C8.99463 15.2469 8.92797 15.2002 8.86131 15.1402C8.74131 15.0136 8.66797 14.8469 8.66797 14.6669C8.66797 14.5802 8.68796 14.4936 8.72129 14.4136C8.75463 14.3269 8.80131 14.2602 8.86131 14.1936C8.92797 14.1336 8.99463 14.0869 9.0813 14.0536C9.3213 13.9469 9.6213 14.0069 9.80796 14.1936C9.86796 14.2602 9.91464 14.3336 9.94798 14.4136C9.98131 14.4936 10.0013 14.5802 10.0013 14.6669C10.0013 14.8402 9.92796 15.0136 9.80796 15.1402C9.6813 15.2602 9.50797 15.3336 9.33464 15.3336Z"
        fill="#77778D"
      />
      <path
        d="M11.9987 15.3329C11.8254 15.3329 11.652 15.2596 11.5254 15.1396C11.4054 15.0129 11.332 14.8463 11.332 14.6663C11.332 14.5796 11.3454 14.4929 11.3854 14.4129C11.4187 14.3329 11.4654 14.2596 11.5254 14.193C11.6787 14.0396 11.912 13.9663 12.1254 14.0129C12.172 14.0196 12.212 14.0329 12.252 14.0529C12.292 14.0663 12.332 14.0863 12.372 14.113C12.4054 14.133 12.4387 14.1663 12.472 14.193C12.532 14.2596 12.5787 14.3329 12.612 14.4129C12.6454 14.4929 12.6654 14.5796 12.6654 14.6663C12.6654 14.8463 12.592 15.0129 12.472 15.1396C12.4387 15.1663 12.4054 15.1929 12.372 15.2196C12.332 15.2463 12.292 15.2663 12.252 15.2796C12.212 15.2996 12.172 15.313 12.1254 15.3196C12.0854 15.3263 12.0387 15.3329 11.9987 15.3329Z"
        fill="#77778D"
      />
      <path
        d="M14.6667 15.3329C14.4867 15.3329 14.32 15.2596 14.1933 15.1396C14.1333 15.0729 14.0867 14.9996 14.0533 14.9196C14.02 14.8396 14 14.7529 14 14.6663C14 14.4929 14.0733 14.3196 14.1933 14.193C14.2267 14.1663 14.26 14.1396 14.2933 14.113C14.3333 14.0863 14.3733 14.0663 14.4133 14.0529C14.4533 14.0329 14.4933 14.0196 14.5333 14.0129C14.7533 13.9663 14.98 14.0396 15.14 14.193C15.26 14.3196 15.3333 14.4863 15.3333 14.6663C15.3333 14.7529 15.3133 14.8396 15.28 14.9196C15.2467 15.0063 15.2 15.0729 15.14 15.1396C15.0133 15.2596 14.84 15.3329 14.6667 15.3329Z"
        fill="#77778D"
      />
    </svg>
  );
}

function LogOutIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F6F6FA" />
      <path
        d="M9.94613 5.5C9.37812 5.49996 9.03212 5.49993 8.73367 5.5472C7.09342 5.80699 5.80699 7.09342 5.5472 8.73367C5.49993 9.03212 5.49996 9.37811 5.5 9.94613L5.5 14.0539C5.49996 14.6219 5.49993 14.9679 5.5472 15.2663C5.80699 16.9066 7.09342 18.193 8.73367 18.4528C9.03213 18.5001 9.37812 18.5001 9.94614 18.5L10.6667 18.5C11.5291 18.5 12.3262 18.2146 12.967 17.7334C13.1878 17.5675 13.2323 17.2541 13.0665 17.0333C12.9006 16.8125 12.5872 16.768 12.3664 16.9338C11.8929 17.2895 11.3051 17.5 10.6667 17.5H10C9.36156 17.5 9.10055 17.4985 8.89011 17.4651C7.67775 17.2731 6.72691 16.3223 6.53489 15.1099C6.50156 14.8995 6.50001 14.6384 6.50001 14L6.50001 10C6.50001 9.36156 6.50156 9.10055 6.53489 8.89011C6.72691 7.67775 7.67775 6.72691 8.89011 6.53489C9.10055 6.50156 9.36156 6.50001 10 6.50001H10.6667C11.3051 6.50001 11.8929 6.71056 12.3664 7.06621C12.5872 7.23206 12.9006 7.18751 13.0665 6.96672C13.2323 6.74592 13.1878 6.43249 12.967 6.26665C12.3262 5.78538 11.5291 5.50001 10.6667 5.50001L9.94613 5.5Z"
        fill="#77778D"
      />
      <path
        d="M15.644 8.94155C15.4276 8.76998 15.1131 8.80631 14.9416 9.02269C14.77 9.23907 14.8063 9.55356 15.0227 9.72513L16.198 10.657C16.6674 11.0292 16.9887 11.2849 17.207 11.5L8.66667 11.5C8.39053 11.5 8.16667 11.7239 8.16667 12C8.16667 12.2761 8.39053 12.5 8.66667 12.5L17.207 12.5C16.9887 12.7151 16.6674 12.9708 16.198 13.343L15.0227 14.2749C14.8063 14.4464 14.77 14.7609 14.9415 14.9773C15.1131 15.1937 15.4276 15.23 15.644 15.0585L16.8432 14.1076C17.2915 13.7522 17.6614 13.4589 17.925 13.1963C18.1954 12.9269 18.4196 12.6279 18.4805 12.2458C18.4935 12.1644 18.5 12.0823 18.5 12C18.5 11.9177 18.4935 11.8356 18.4805 11.7542C18.4196 11.3721 18.1954 11.0731 17.925 10.8037C17.6614 10.5412 17.2915 10.2478 16.8432 9.89238L15.644 8.94155Z"
        fill="#77778D"
      />
    </svg>
  );
}

function CameraIcon() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.9987 6.16699C6.80208 6.16699 5.83203 7.13704 5.83203 8.33366C5.83203 9.53028 6.80208 10.5003 7.9987 10.5003C9.19531 10.5003 10.1654 9.53028 10.1654 8.33366C10.1654 7.13704 9.19531 6.16699 7.9987 6.16699Z"
        fill="#77778D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.16536 0.166992C5.24489 0.166992 4.4987 0.913184 4.4987 1.83366V1.95271C4.4987 2.25515 4.25352 2.50033 3.95108 2.50033C2.22848 2.50033 0.832031 3.89677 0.832031 5.61937V8.69323C0.832027 9.76215 0.832024 10.6001 0.895676 11.2696C0.960556 11.952 1.09508 12.5107 1.39691 13.0032C1.71299 13.519 2.14666 13.9527 2.66245 14.2688C3.155 14.5706 3.71374 14.7051 4.3961 14.77C5.06555 14.8337 5.90351 14.8337 6.97241 14.8337H9.02495C10.0938 14.8337 10.9318 14.8337 11.6013 14.77C12.2837 14.7051 12.8424 14.5706 13.3349 14.2688C13.8507 13.9527 14.2844 13.519 14.6005 13.0032C14.9023 12.5107 15.0368 11.952 15.1017 11.2696C15.1654 10.6001 15.1654 9.76218 15.1654 8.69328V5.61937C15.1654 3.89677 13.7689 2.50033 12.0463 2.50033C11.7439 2.50033 11.4987 2.25515 11.4987 1.95271V1.83366C11.4987 0.913184 10.7525 0.166992 9.83203 0.166992H6.16536ZM4.83203 8.33366C4.83203 6.58476 6.2498 5.16699 7.9987 5.16699C9.7476 5.16699 11.1654 6.58476 11.1654 8.33366C11.1654 10.0826 9.7476 11.5003 7.9987 11.5003C6.2498 11.5003 4.83203 10.0826 4.83203 8.33366ZM11.9987 6.33366C11.6305 6.33366 11.332 6.03518 11.332 5.66699C11.332 5.2988 11.6305 5.00033 11.9987 5.00033C12.3669 5.00033 12.6654 5.2988 12.6654 5.66699C12.6654 6.03518 12.3669 6.33366 11.9987 6.33366Z"
        fill="#77778D"
      />
    </svg>
  );
}

function BackIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.57031 5.92969L3.50031 11.9997L9.57031 18.0697"
        stroke="#707581"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.5 12H3.67"
        stroke="#707581"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function TransactionHistoryIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F6F6FA" />
      <path
        d="M18.6663 10.167H5.33301C5.05967 10.167 4.83301 9.94033 4.83301 9.66699C4.83301 9.39366 5.05967 9.16699 5.33301 9.16699H18.6663C18.9397 9.16699 19.1663 9.39366 19.1663 9.66699C19.1663 9.94033 18.9397 10.167 18.6663 10.167Z"
        fill="#707581"
      />
      <path
        d="M9.33333 15.5H8C7.72667 15.5 7.5 15.2733 7.5 15C7.5 14.7267 7.72667 14.5 8 14.5H9.33333C9.60667 14.5 9.83333 14.7267 9.83333 15C9.83333 15.2733 9.60667 15.5 9.33333 15.5Z"
        fill="#707581"
      />
      <path
        d="M13.6667 15.5H11C10.7267 15.5 10.5 15.2733 10.5 15C10.5 14.7267 10.7267 14.5 11 14.5H13.6667C13.94 14.5 14.1667 14.7267 14.1667 15C14.1667 15.2733 13.94 15.5 13.6667 15.5Z"
        fill="#707581"
      />
      <path
        d="M15.7063 18.1663H8.29301C5.63967 18.1663 4.83301 17.3663 4.83301 14.7397V9.25967C4.83301 6.63301 5.63967 5.83301 8.29301 5.83301H15.6997C18.353 5.83301 19.1597 6.63301 19.1597 9.25967V14.733C19.1663 17.3663 18.3597 18.1663 15.7063 18.1663ZM8.29301 6.83301C6.19967 6.83301 5.83301 7.19301 5.83301 9.25967V14.733C5.83301 16.7997 6.19967 17.1597 8.29301 17.1597H15.6997C17.793 17.1597 18.1597 16.7997 18.1597 14.733V9.25967C18.1597 7.19301 17.793 6.83301 15.6997 6.83301H8.29301Z"
        fill="#707581"
      />
    </svg>
  );
}

function SearchIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5304 17.4698C18.2375 17.1769 17.7626 17.1769 17.4697 17.4698C17.1768 17.7626 17.1768 18.2375 17.4697 18.5304L18.5304 17.4698ZM21.4696 22.5304C21.7625 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L21.4696 22.5304ZM17.4697 18.5304L21.4696 22.5304L22.5303 21.4697L18.5304 17.4698L17.4697 18.5304ZM10 18.25C5.44365 18.25 1.75 14.5563 1.75 10H0.25C0.25 15.3848 4.61522 19.75 10 19.75V18.25ZM18.25 10C18.25 14.5563 14.5563 18.25 10 18.25V19.75C15.3848 19.75 19.75 15.3848 19.75 10H18.25ZM10 1.75C14.5563 1.75 18.25 5.44365 18.25 10H19.75C19.75 4.61522 15.3848 0.25 10 0.25V1.75ZM10 0.25C4.61522 0.25 0.25 4.61522 0.25 10H1.75C1.75 5.44365 5.44365 1.75 10 1.75V0.25Z"
        fill="#707581"
      />
    </svg>
  );
}

function PlusIcon() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.81934L8 16.1589"
        stroke="#707581"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 9.54102L1 9.54102"
        stroke="#707581"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

function OpenIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16.3299C9.60998 16.3299 7.66998 14.3899 7.66998 11.9999C7.66998 9.60992 9.60998 7.66992 12 7.66992C14.39 7.66992 16.33 9.60992 16.33 11.9999C16.33 14.3899 14.39 16.3299 12 16.3299ZM12 9.16992C10.44 9.16992 9.16998 10.4399 9.16998 11.9999C9.16998 13.5599 10.44 14.8299 12 14.8299C13.56 14.8299 14.83 13.5599 14.83 11.9999C14.83 10.4399 13.56 9.16992 12 9.16992Z"
        fill="#707581"
      />
      <path
        d="M12 21.0205C8.24002 21.0205 4.69002 18.8205 2.25002 15.0005C1.19002 13.3505 1.19002 10.6605 2.25002 9.00047C4.70002 5.18047 8.25002 2.98047 12 2.98047C15.75 2.98047 19.3 5.18047 21.74 9.00047C22.8 10.6505 22.8 13.3405 21.74 15.0005C19.3 18.8205 15.75 21.0205 12 21.0205ZM12 4.48047C8.77002 4.48047 5.68002 6.42047 3.52002 9.81047C2.77002 10.9805 2.77002 13.0205 3.52002 14.1905C5.68002 17.5805 8.77002 19.5205 12 19.5205C15.23 19.5205 18.32 17.5805 20.48 14.1905C21.23 13.0205 21.23 10.9805 20.48 9.81047C18.32 6.42047 15.23 4.48047 12 4.48047Z"
        fill="#707581"
      />
    </svg>
  );
}

function CloseIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.46998 15.2799C9.27998 15.2799 9.08998 15.2099 8.93998 15.0599C8.11998 14.2399 7.66998 13.1499 7.66998 11.9999C7.66998 9.60992 9.60998 7.66992 12 7.66992C13.15 7.66992 14.24 8.11992 15.06 8.93992C15.2 9.07992 15.28 9.26992 15.28 9.46992C15.28 9.66992 15.2 9.85992 15.06 9.99992L9.99998 15.0599C9.84998 15.2099 9.65998 15.2799 9.46998 15.2799ZM12 9.16992C10.44 9.16992 9.16998 10.4399 9.16998 11.9999C9.16998 12.4999 9.29998 12.9799 9.53998 13.3999L13.4 9.53992C12.98 9.29992 12.5 9.16992 12 9.16992Z"
        fill="#707581"
      />
      <path
        d="M5.60003 18.5105C5.43003 18.5105 5.25003 18.4505 5.11003 18.3305C4.04003 17.4205 3.08003 16.3005 2.26003 15.0005C1.20003 13.3505 1.20003 10.6605 2.26003 9.00047C4.70003 5.18047 8.25003 2.98047 12 2.98047C14.2 2.98047 16.37 3.74047 18.27 5.17047C18.6 5.42047 18.67 5.89047 18.42 6.22047C18.17 6.55047 17.7 6.62047 17.37 6.37047C15.73 5.13047 13.87 4.48047 12 4.48047C8.77003 4.48047 5.68003 6.42047 3.52003 9.81047C2.77003 10.9805 2.77003 13.0205 3.52003 14.1905C4.27003 15.3605 5.13003 16.3705 6.08003 17.1905C6.39003 17.4605 6.43003 17.9305 6.16003 18.2505C6.02003 18.4205 5.81003 18.5105 5.60003 18.5105Z"
        fill="#707581"
      />
      <path
        d="M12 21.0205C10.67 21.0205 9.37 20.7505 8.12 20.2205C7.74 20.0605 7.56 19.6205 7.72 19.2405C7.88 18.8605 8.32 18.6805 8.7 18.8405C9.76 19.2905 10.87 19.5205 11.99 19.5205C15.22 19.5205 18.31 17.5805 20.47 14.1905C21.22 13.0205 21.22 10.9805 20.47 9.81049C20.16 9.32049 19.82 8.85049 19.46 8.41049C19.2 8.09049 19.25 7.62049 19.57 7.35049C19.89 7.09049 20.36 7.13049 20.63 7.46049C21.02 7.94049 21.4 8.46049 21.74 9.00049C22.8 10.6505 22.8 13.3405 21.74 15.0005C19.3 18.8205 15.75 21.0205 12 21.0205Z"
        fill="#707581"
      />
      <path
        d="M12.69 16.2703C12.34 16.2703 12.02 16.0203 11.95 15.6603C11.87 15.2503 12.14 14.8603 12.55 14.7903C13.65 14.5903 14.57 13.6703 14.77 12.5703C14.85 12.1603 15.24 11.9003 15.65 11.9703C16.06 12.0503 16.33 12.4403 16.25 12.8503C15.93 14.5803 14.55 15.9503 12.83 16.2703C12.78 16.2603 12.74 16.2703 12.69 16.2703Z"
        fill="#707581"
      />
      <path
        d="M2 22.7497C1.81 22.7497 1.62 22.6797 1.47 22.5297C1.18 22.2397 1.18 21.7597 1.47 21.4697L8.94 13.9997C9.23 13.7097 9.71 13.7097 10 13.9997C10.29 14.2897 10.29 14.7697 10 15.0597L2.53 22.5297C2.38 22.6797 2.19 22.7497 2 22.7497Z"
        fill="#707581"
      />
      <path
        d="M14.53 10.2204C14.34 10.2204 14.15 10.1504 14 10.0004C13.71 9.71043 13.71 9.23043 14 8.94043L21.47 1.47043C21.76 1.18043 22.24 1.18043 22.53 1.47043C22.82 1.76043 22.82 2.24043 22.53 2.53043L15.06 10.0004C14.91 10.1504 14.72 10.2204 14.53 10.2204Z"
        fill="#707581"
      />
    </svg>
  );
}

function DownloadIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16.5L11.4697 17.0303L12 17.5607L12.5303 17.0303L12 16.5ZM12.75 4.5C12.75 4.08579 12.4142 3.75 12 3.75C11.5858 3.75 11.25 4.08579 11.25 4.5L12.75 4.5ZM5.46967 11.0303L11.4697 17.0303L12.5303 15.9697L6.53033 9.96967L5.46967 11.0303ZM12.5303 17.0303L18.5303 11.0303L17.4697 9.96967L11.4697 15.9697L12.5303 17.0303ZM12.75 16.5L12.75 4.5L11.25 4.5L11.25 16.5L12.75 16.5Z"
        fill="black"
      />
      <path d="M5 21.5L19 21.5" stroke="black" stroke-width="1.5" />
    </svg>
  );
}

function ModalCrossIcon() {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8415 5.09862C18.4134 4.67129 17.7428 4.66998 17.3138 5.09633L11.7471 10.628L6.18041 5.09633C5.75136 4.66998 5.08082 4.67129 4.65265 5.09863C4.18841 5.56196 4.18861 6.34223 4.6538 6.8045L10.1243 12.2406L4.6538 17.6767C4.18861 18.139 4.18841 18.9192 4.65265 19.3826C5.08082 19.8099 5.75137 19.8112 6.18042 19.3849L11.7471 13.8532L17.3138 19.3849C17.7428 19.8112 18.4134 19.8099 18.8415 19.3826C19.3058 18.9192 19.3056 18.139 18.8404 17.6767L13.3699 12.2406L18.8404 6.8045C19.3056 6.34223 19.3058 5.56196 18.8415 5.09862Z"
        fill="#707581"
      />
    </svg>
  );
}

function StarIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3195 5.43182C11.3361 2.8974 11.8444 1.63019 12.6702 1.45456C12.8874 1.40838 13.1118 1.40838 13.3289 1.45456C14.1548 1.63019 14.6631 2.8974 15.6797 5.43182C16.2578 6.87309 16.5469 7.59373 17.0878 8.08388C17.2395 8.22136 17.4042 8.3438 17.5795 8.44949C18.2047 8.82629 18.9851 8.89618 20.5459 9.03596C23.1881 9.27259 24.5092 9.39091 24.9127 10.1442C24.9962 10.3002 25.053 10.4691 25.0807 10.6439C25.2145 11.4878 24.2433 12.3714 22.3009 14.1386L21.7615 14.6294C20.8534 15.4556 20.3993 15.8687 20.1367 16.3842C19.9791 16.6934 19.8735 17.0265 19.824 17.37C19.7415 17.9427 19.8744 18.542 20.1404 19.7405L20.2354 20.1688C20.7123 22.3182 20.9507 23.3929 20.6531 23.9212C20.3857 24.3957 19.8932 24.6994 19.3492 24.7254C18.7435 24.7544 17.8901 24.059 16.1833 22.6682C15.0588 21.7519 14.4966 21.2937 13.8724 21.1147C13.302 20.9512 12.6971 20.9512 12.1267 21.1147C11.5026 21.2937 10.9403 21.7519 9.81584 22.6682C8.10904 24.059 7.25565 24.7544 6.64999 24.7254C6.10596 24.6994 5.61347 24.3957 5.3461 23.9212C5.04844 23.3929 5.28689 22.3182 5.76378 20.1688L5.85879 19.7405C6.12472 18.542 6.25768 17.9427 6.17517 17.37C6.12567 17.0265 6.02004 16.6934 5.8625 16.3842C5.59986 15.8687 5.14581 15.4556 4.23769 14.6294L3.6983 14.1386C1.75588 12.3714 0.784672 11.4878 0.918422 10.6439C0.946123 10.4691 1.00293 10.3002 1.08649 10.1442C1.48992 9.39091 2.81102 9.27259 5.45323 9.03596C7.01405 8.89618 7.79447 8.82629 8.41963 8.44949C8.59498 8.3438 8.75967 8.22136 8.91138 8.08388C9.45226 7.59373 9.74133 6.87309 10.3195 5.43182Z"
        stroke="#707581"
      />
    </svg>
  );
}
function HalfStarIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3195 4.43182C11.3361 1.8974 11.8444 0.630187 12.6702 0.454557C12.8874 0.408377 13.1118 0.408377 13.3289 0.454557C14.1548 0.630187 14.6631 1.8974 15.6797 4.43182C16.2578 5.87309 16.5469 6.59373 17.0878 7.08388C17.2395 7.22136 17.4042 7.3438 17.5795 7.44949C18.2047 7.82628 18.9851 7.89618 20.5459 8.03596C23.1881 8.27259 24.5092 8.39091 24.9127 9.14417C24.9962 9.30017 25.053 9.46908 25.0807 9.64388C25.2145 10.4878 24.2433 11.3714 22.3009 13.1386L21.7615 13.6294C20.8534 14.4556 20.3993 14.8687 20.1367 15.3842C19.9791 15.6934 19.8735 16.0265 19.824 16.37C19.7415 16.9427 19.8744 17.542 20.1404 18.7405L20.2354 19.1688C20.7123 21.3182 20.9507 22.3929 20.6531 22.9212C20.3857 23.3957 19.8932 23.6994 19.3492 23.7254C18.7435 23.7544 17.8901 23.059 16.1833 21.6682C15.0588 20.7519 14.4966 20.2937 13.8724 20.1147C13.302 19.9512 12.6971 19.9512 12.1267 20.1147C11.5026 20.2937 10.9403 20.7519 9.81584 21.6682C8.10904 23.059 7.25565 23.7544 6.64999 23.7254C6.10596 23.6994 5.61347 23.3957 5.3461 22.9212C5.04844 22.3929 5.28689 21.3182 5.76378 19.1688L5.85879 18.7405C6.12472 17.542 6.25768 16.9427 6.17517 16.37C6.12567 16.0265 6.02004 15.6934 5.8625 15.3842C5.59986 14.8687 5.14581 14.4556 4.23769 13.6294L3.6983 13.1386C1.75588 11.3714 0.784672 10.4878 0.918422 9.64388C0.946123 9.46908 1.00293 9.30017 1.08649 9.14417C1.48992 8.39091 2.81102 8.27259 5.45323 8.03596C7.01405 7.89618 7.79447 7.82628 8.41963 7.44949C8.59498 7.3438 8.75967 7.22136 8.91138 7.08388C9.45226 6.59373 9.74133 5.87309 10.3195 4.43182Z"
        fill="#DBBB51"
      />
    </svg>
  );
}
function FullStarIcon() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3195 4.43182C11.3361 1.8974 11.8444 0.630187 12.6702 0.454557C12.8874 0.408377 13.1118 0.408377 13.3289 0.454557C14.1548 0.630187 14.6631 1.8974 15.6797 4.43182C16.2578 5.87309 16.5469 6.59373 17.0878 7.08388C17.2395 7.22136 17.4042 7.3438 17.5795 7.44949C18.2047 7.82628 18.9851 7.89618 20.5459 8.03596C23.1881 8.27259 24.5092 8.39091 24.9127 9.14417C24.9962 9.30017 25.053 9.46908 25.0807 9.64388C25.2145 10.4878 24.2433 11.3714 22.3009 13.1386L21.7615 13.6294C20.8534 14.4556 20.3993 14.8687 20.1367 15.3842C19.9791 15.6934 19.8735 16.0265 19.824 16.37C19.7415 16.9427 19.8744 17.542 20.1404 18.7405L20.2354 19.1688C20.7123 21.3182 20.9507 22.3929 20.6531 22.9212C20.3857 23.3957 19.8932 23.6994 19.3492 23.7254C18.7435 23.7544 17.8901 23.059 16.1833 21.6682C15.0588 20.7519 14.4966 20.2937 13.8724 20.1147C13.302 19.9512 12.6971 19.9512 12.1267 20.1147C11.5026 20.2937 10.9403 20.7519 9.81584 21.6682C8.10904 23.059 7.25565 23.7544 6.64999 23.7254C6.10596 23.6994 5.61347 23.3957 5.3461 22.9212C5.04844 22.3929 5.28689 21.3182 5.76378 19.1688L5.85879 18.7405C6.12472 17.542 6.25768 16.9427 6.17517 16.37C6.12567 16.0265 6.02004 15.6934 5.8625 15.3842C5.59986 14.8687 5.14581 14.4556 4.23769 13.6294L3.6983 13.1386C1.75588 11.3714 0.784672 10.4878 0.918422 9.64388C0.946123 9.46908 1.00293 9.30017 1.08649 9.14417C1.48992 8.39091 2.81102 8.27259 5.45323 8.03596C7.01405 7.89618 7.79447 7.82628 8.41963 7.44949C8.59498 7.3438 8.75967 7.22136 8.91138 7.08388C9.45226 6.59373 9.74133 5.87309 10.3195 4.43182Z"
        fill="#DBBB51"
      />
    </svg>
  );
}

function SignupUpload() {
  return (
    <svg
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="45"
        cy="45"
        r="44.5"
        fill="#F6F6FA"
        stroke="#707581"
        stroke-dasharray="6 6"
      />
      <path
        d="M32.3438 41.9823C32.3438 40.5423 33.5111 39.375 34.9511 39.375V39.375C35.9387 39.375 36.8415 38.817 37.2831 37.9337L38.4375 35.625C38.8103 34.8794 38.9967 34.5067 39.2895 34.2489C39.4583 34.1002 39.651 33.9811 39.8595 33.8966C40.221 33.75 40.6378 33.75 41.4713 33.75H48.5287C49.3622 33.75 49.779 33.75 50.1405 33.8966C50.349 33.9811 50.5417 34.1002 50.7105 34.2489C51.0033 34.5067 51.1897 34.8794 51.5625 35.625L52.7169 37.9337C53.1585 38.817 54.0613 39.375 55.0489 39.375V39.375C56.4889 39.375 57.6562 40.5423 57.6562 41.9823V50.25C57.6562 53.0784 57.6562 54.4926 56.7776 55.3713C55.8989 56.25 54.4847 56.25 51.6563 56.25H38.3438C35.5153 56.25 34.1011 56.25 33.2224 55.3713C32.3438 54.4926 32.3438 53.0784 32.3438 50.25V41.9823Z"
        stroke="#707581"
        stroke-width="1.5"
      />
      <circle
        cx="45"
        cy="46.4062"
        r="4.875"
        stroke="#707581"
        stroke-width="1.5"
      />
    </svg>
  );
}

function NotiDropDown() {
  return (
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.53269 0.472041C1.24111 0.177844 0.766238 0.175725 0.472041 0.467309C0.177844 0.758893 0.175726 1.23376 0.467309 1.52796L2.2461 3.32269C2.91604 3.99866 3.46359 4.55114 3.95146 4.94294C4.45878 5.35037 4.97372 5.64531 5.59184 5.72409C5.86286 5.75864 6.13714 5.75864 6.40816 5.72409C7.02628 5.64531 7.54122 5.35037 8.04854 4.94294C8.53641 4.55114 9.08395 3.99867 9.75389 3.3227L11.5327 1.52796C11.8243 1.23376 11.8222 0.758893 11.528 0.46731C11.2338 0.175726 10.7589 0.177845 10.4673 0.472042L8.72 2.23501C8.01086 2.9505 7.52282 3.44131 7.10929 3.77341C6.7076 4.096 6.44958 4.20668 6.2185 4.23613C6.07341 4.25462 5.92659 4.25462 5.7815 4.23613C5.55042 4.20668 5.2924 4.096 4.89071 3.77341C4.47718 3.44131 3.98914 2.9505 3.28 2.23501L1.53269 0.472041Z"
        fill="#DBBB51"
      />
    </svg>
  );
}

function SeenIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625ZM9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625Z"
        fill="#707581"
      />
      <path
        d="M7.93312 11.6852C7.78312 11.6852 7.64062 11.6252 7.53562 11.5202L5.41312 9.3977C5.19563 9.1802 5.19563 8.8202 5.41312 8.6027C5.63062 8.3852 5.99062 8.3852 6.20812 8.6027L7.93312 10.3277L11.7881 6.4727C12.0056 6.2552 12.3656 6.2552 12.5831 6.4727C12.8006 6.6902 12.8006 7.05019 12.5831 7.26769L8.33062 11.5202C8.22562 11.6252 8.08312 11.6852 7.93312 11.6852Z"
        fill="#707581"
      />
    </svg>
  );
}

function NewSearchIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5304 17.4698C19.2375 17.1769 18.7626 17.1769 18.4697 17.4698C18.1768 17.7626 18.1768 18.2375 18.4697 18.5304L19.5304 17.4698ZM22.4696 22.5304C22.7625 22.8233 23.2374 22.8233 23.5303 22.5304C23.8232 22.2375 23.8232 21.7626 23.5303 21.4697L22.4696 22.5304ZM18.4697 18.5304L22.4696 22.5304L23.5303 21.4697L19.5304 17.4698L18.4697 18.5304ZM11 18.25C6.44365 18.25 2.75 14.5563 2.75 10H1.25C1.25 15.3848 5.61522 19.75 11 19.75V18.25ZM19.25 10C19.25 14.5563 15.5563 18.25 11 18.25V19.75C16.3848 19.75 20.75 15.3848 20.75 10H19.25ZM11 1.75C15.5563 1.75 19.25 5.44365 19.25 10H20.75C20.75 4.61522 16.3848 0.25 11 0.25V1.75ZM11 0.25C5.61522 0.25 1.25 4.61522 1.25 10H2.75C2.75 5.44365 6.44365 1.75 11 1.75V0.25Z"
        fill="#3A3B3B"
      />
    </svg>
  );
}

function NewCrossIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.2788 4.23769C19.8362 3.82377 19.1436 3.82303 18.7 4.23599L12.6513 9.86777L6.6025 4.23599C6.15895 3.82303 5.46632 3.82377 5.02368 4.23769L4.94729 4.30913C4.46737 4.75792 4.4682 5.5131 4.94911 5.96086L10.8983 11.4999L4.94911 17.0389C4.4682 17.4867 4.46737 18.2419 4.94729 18.6907L5.02368 18.7621C5.46632 19.176 6.15896 19.1768 6.6025 18.7638L12.6513 13.132L18.7 18.7638C19.1436 19.1768 19.8362 19.176 20.2788 18.7621L20.3552 18.6907C20.8351 18.2419 20.8343 17.4867 20.3534 17.0389L14.4042 11.4999L20.3534 5.96086C20.8343 5.5131 20.8351 4.75791 20.3552 4.30912L20.2788 4.23769Z"
        fill="#363636"
      />
    </svg>
  );
}
export {
  AccountDetailsIcon,
  AddressIcon,
  MyOrdersIcon,
  PrivacyIcon,
  TermsIcon,
  ContactIcon,
  ChangePasswordIcon,
  LogOutIcon,
  CameraIcon,
  BackIcon,
  TransactionHistoryIcon,
  SearchIcon,
  PlusIcon,
  OpenIcon,
  CloseIcon,
  DownloadIcon,
  ModalCrossIcon,
  StarIcon,
  FullStarIcon,
  HalfStarIcon,
  SignupUpload,
  NotiDropDown,
  SeenIcon,
  NewSearchIcon,
  NewCrossIcon
};
