import { React, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Assets } from "../../constant/images";
import { Col, InputGroup, Row } from "react-bootstrap";
import {
  AllHome,
  Basket,
  BuyerDropdown,
  BuyerDropdownProfile,
  CategoryCrossIcon,
  ChatMessageIcon,
  DropdownIcon,
  Heart,
  MegamenuRightIcon,
  MenuIcon,
  NotificationBuyerIcon,
  SearchIcon,
} from "../../constant/svg";
import { NavLink } from "react-router-dom";
import Dropdown from "react-multilevel-dropdown";
import NotificationModal from "../Modals/NotificationModal";
import DropdownMenu from "./DropdownMenu";

const NavbarHeader = ({ isShow, isDontShow }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [notifmodalShow, SetNotifModalShow] = useState(false);

  return (
    <>
      <div className="main-section navbar-bg">
        <Container>
          {["md"].map((expand) => (
            <Navbar key={expand} expand={expand} className="navbarheader">
              <Container fluid>
                <NavLink to="/home">
                  <Navbar.Brand>
                    <img src={Assets.Logo} />
                  </Navbar.Brand>
                </NavLink>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                  
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    >
                      <NavLink to="/home">
                        <Navbar.Brand>
                          <img src={Assets.Logo} />
                        </Navbar.Brand>
                      </NavLink>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                      <InputGroup className="search-bar">
                        <Form.Control
                          placeholder="I'm searching for..."
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />
                        <InputGroup.Text id="basic-addon2">
                         <span className="cursor-pointer"> <SearchIcon /> </span>
                        </InputGroup.Text>
                      </InputGroup>
                      {/* <div>
                        <NavLink to="/getstarted">
                          <Button className="signin-btn ms_16">
                            Sign in
                          </Button>
                        </NavLink>
                      </div> */}
                      <div>
                        {isShow && (
                          <NavLink to="/signin">
                            <Button className="signin-btn ms_16">
                              Sign in
                            </Button>
                          </NavLink>
                        )}
                      </div>
                      <NavLink to="/wishlist">
                        <div className="heart-container ms_16">
                          <Heart />
                        </div>
                      </NavLink>
                      <NavLink to="/cart-screen">
                        <div className="heart-container ms_16">
                          <Basket />
                        </div>
                      </NavLink>
                      {/* <div className="heart-container ms_16">
                        <ChatMessageIcon />
                      </div> */}
                      {isDontShow && (
                        <div className="heart-container ms_16">
                          <ChatMessageIcon />
                        </div>
                      )}
                      <div
                        className="heart-container ms_16 cursor-link"
                        onClick={() => SetNotifModalShow(true)}
                      >
                        <NotificationBuyerIcon />
                      </div>
                      {isDontShow && (
                        <NavLink to="/buyer-account-details">
                          <div className="buyer-navbar-profile ms_16 d-flex align-items-center justify-content-between">
                            <div>
                              <BuyerDropdown />
                            </div>
                            <div className="img-box">
                              <img src={Assets.BuyerProfilePic} />
                            </div>
                          </div>
                        </NavLink>
                      )}
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
          <div className="divider">
            <hr />
          </div>
          <div className="header-bottombar">
            <div className="d-flex align-items-center">
              <div>
                <Button className="category-btn" onClick={handleShow}>
                  <MenuIcon /> Category
                </Button>
              </div>
              <div className="menu-options d-none d-lg-block">
                <ul>
                  <li className="nav-multidropdown">
                    Health & Beauty <DropdownIcon />
                        <DropdownMenu/>
                  </li>
                  <li  className="nav-multidropdown">
                    Clothing & Fashion <DropdownIcon />
                  </li>
                  <li  className="nav-multidropdown">
                    Technics & Devices <DropdownIcon />
                  </li>
                  <li  className="nav-multidropdown">
                    Toys & Handicrafts <DropdownIcon />
                  </li>
                  <li  className="nav-multidropdown">
                    Sports & training <DropdownIcon />
                  </li>
                  <li  className="nav-multidropdown">
                    Home & Living <DropdownIcon />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          className="category-btn-offcanvas"
        >
          <Offcanvas.Body>
            <div className="d-flex align-items-center justify-content-between">
              <div className="category-heading">All Categories</div>
              <div onClick={handleClose}>
                <CategoryCrossIcon />
              </div>
            </div>
            <div className="boxes-section">
              <div className="category-boxes">Daily Deals</div>
              <div className="category-boxes">Top Promotions</div>
              <div className="category-boxes">New Arrival</div>
              <div className="category-boxes">Brands</div>
              <div className="category-boxes">Handmade</div>
              <div className="category-boxes">Home & Living</div>
              <div className="category-boxes">Clothing & Fashion</div>
              <div className="category-boxes">Health & Beauty </div>
              <div className="category-boxes">Techniques & Devices</div>
              <div className="category-boxes">Toys & Handicrafts</div>
              <div className="category-boxes">Sports & Training</div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <NotificationModal
        show={notifmodalShow}
        onHide={() => SetNotifModalShow(false)}
      />
    </>
  );
};

export default NavbarHeader;
