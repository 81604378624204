import React, { useState, useEffect } from "react";
import { Assets } from "../../../constant/images";
import { NavLink, useNavigate } from "react-router-dom";
import { CameraIcon } from "../../../utils/svg";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../../constant/app-constants";

const BuyerAccountDetailsEdit = () => {
  const [editprofile, setEditProfile] = useState(false);

  const [profilePic, setProfilePic] = useState({
    file: null,
    file_url: null,
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");
  const [options, setOptions] = useState([
    { value: "0", label: "United States" },
    { value: "1", label: "England" },
    { value: "2", label: "Canada" },
  ]);
  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("/buyer-account-details");
  };

  useEffect(() => {
    reset({
      name: "Sidney Wilams",
      email: "johnsmith@gmail.com",
      phone: "+01-143553464",
      address: "217 E Bondo Street, New York",
      country: options[0].value,
      city: "California",
      zip_code: 12345,
      state: "Brooklyn"
    });
  }, []);

  return (
    <>
      <div className="main-buyer-account-details-edit">
        <div className="top-heading-edit-div">
          <div className="heading-div">
            <h4>Account Details</h4>
          </div>
        </div>

        <div className="buyer-account-content">
          <div className="buyer-avatar">
            <img
              src={
                profilePic.file_url ? profilePic.file_url : Assets.BuyerAvatar
              }
              alt=""
              className="w-100 h-100 img-upload"
            />

            {/* <img className="img-fluid" src={Assets.BuyerAvatar} /> */}
          </div>
          <div className="camera-div">
            <label htmlFor="img-upload" className="file-upload">
              <input
                type="file"
                id="img-upload"
                name="avatar"
                accept=".png, .jpg, .jpeg"
                className="d-none"
                onChange={(e) =>
                  setProfilePic({
                    file: e.target.files[0],
                    file_url: URL.createObjectURL(e.target.files[0]),
                  })
                }
              />

              <CameraIcon />
            </label>
          </div>
          <div className="buyer-name">
            <h2>Sidney Wilams</h2>
          </div>
          {/* <div className="buyer-info">
            <ul>
              <li>
                <span>Email Address:</span> <span>johnsmith@gmail.com</span>
              </li>
              <li>
                <span>Phone Number:</span> <span>+01-143553464</span>
              </li>
              <li>
                <span>Country:</span> <span>United States</span>
              </li>
              <li>
                <span>Address:</span> <span>217 E Bondo Street, New York</span>
              </li>
              <li>
                <span>City:</span> <span>New York</span>
              </li>
              <li>
                <span>Zipcode:</span> <span>12345</span>
              </li>
            </ul>
          </div> */}
          <div className="buyer-contact-fields signup-form">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                {/* <Form.Group
                  as={Col}
                  controlId="formGridEmail"
                  className="form-group"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sidney Wilams"
                    onKeyDown={(e) =>
                      EXCLUDE_NUMBER_INPUT.includes(e.key) && e.preventDefault()
                    }
                    maxLength={VALIDATIONS.NAME}
                    {...register("name", {
                      maxLength: {
                        value: VALIDATIONS.NAME,
                        message: VALIDATIONS_TEXT.NAME_MAX,
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]*$/,
                        message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.NAME_REQUIRED,
                      },
                    })}
                  />
                  {errors.name && (
                    <small className="text-red">{errors.name.message}</small>
                  )}
                </Form.Group> */}
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label="Your Name">
                    <Form.Control
                      placeholder="Your Name"
                      type="text"
                      onKeyDown={(e) =>
                        EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                        e.preventDefault()
                      }
                      maxLength={VALIDATIONS.NAME}
                      {...register("name", {
                        maxLength: {
                          value: VALIDATIONS.NAME,
                          message: VALIDATIONS_TEXT.NAME_MAX,
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.NAME_REQUIRED,
                        },
                      })}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name.message}</small>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md={6}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email Address"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      maxLength={VALIDATIONS.EMAIL}
                      {...register("email", {
                        maxLength: {
                          value: VALIDATIONS.EMAIL,
                          message: VALIDATIONS_TEXT.EMAIL_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                        },
                        pattern: {
                          value:
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                          message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                        },
                      })}
                    />
                    {errors.email && (
                      <small className="text-red error_from">
                        {errors.email.message}
                      </small>
                    )}
                  </FloatingLabel>
                </Col>
                {/* <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="johnsmith@gmail.com"
                    maxLength={VALIDATIONS.EMAIL}
                    {...register("email", {
                      maxLength: {
                        value: VALIDATIONS.EMAIL,
                        message: VALIDATIONS_TEXT.EMAIL_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                      },
                      pattern: {
                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                        message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                      },
                    })}
                  />
                  {errors.email && (
                    <small className="text-red error_from">
                      {errors.email.message}
                    </small>
                  )}
                </Form.Group> */}
              </Row>

              <Row>
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label="Phone Number">
                    <Form.Control
                      type="tel"
                      placeholder="+01-143553464"
                      onKeyDown={(e) => {
                        EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                          e.key.toUpperCase()
                        ) && e.preventDefault();
                      }}
                      maxLength={VALIDATIONS.PHONE_MAX}
                      {...register("phone", {
                        pattern: {
                          value:
                            /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                          message: VALIDATIONS_TEXT.INVALID_PHONE_FORMAT,
                        },
                        minLength: {
                          value: VALIDATIONS.PHONE_MIN,
                          message: VALIDATIONS_TEXT.PHONE_MIN,
                        },
                        maxLength: {
                          value: VALIDATIONS.PHONE_MAX,
                          message: VALIDATIONS_TEXT.PHONE_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                        },
                      })}
                    />
                    {errors.phone && (
                      <small className="text-red">{errors.phone.message}</small>
                    )}
                  </FloatingLabel>
                </Col>
                {/* <Form.Group
                  as={Col}
                  controlId="formGridEmail"
                  className="form-group"
                >
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="+01-143553464"
                    onKeyDown={(e) => {
                      EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                        e.key.toUpperCase()
                      ) && e.preventDefault();
                    }}
                    maxLength={VALIDATIONS.PHONE_MAX}
                    {...register("phone", {
                      pattern: {
                        value:
                          /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                        message: VALIDATIONS_TEXT.INVALID_PHONE_FORMAT,
                      },
                      minLength: {
                        value: VALIDATIONS.PHONE_MIN,
                        message: VALIDATIONS_TEXT.PHONE_MIN,
                      },
                      maxLength: {
                        value: VALIDATIONS.PHONE_MAX,
                        message: VALIDATIONS_TEXT.PHONE_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                      },
                    })}
                  />
                  {errors.phone && (
                    <small className="text-red">{errors.phone.message}</small>
                  )}
                </Form.Group> */}

                <Col md={6}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Address Line 1"
                  >
                    <Form.Control
                      type="text"
                      placeholder="217 E Bondo Street, New York"
                      maxLength={VALIDATIONS.ADDRESS_MAX}
                      {...register("address", {
                        maxLength: {
                          value: VALIDATIONS.ADDRESS_MAX,
                          message: VALIDATIONS_TEXT.ADDRESS_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.ADDRESS_REQUIRED,
                        },
                      })}
                    />
                    {errors.address && (
                      <small className="text-red">
                        {errors.address.message}
                      </small>
                    )}
                  </FloatingLabel>
                </Col>
                {/* <Form.Group
                  as={Col}
                  controlId="formGridEmail"
                  className="form-group"
                >
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="217 E Bondo Street, New York"
                    maxLength={VALIDATIONS.ADDRESS_MAX}
                    {...register("address", {
                      maxLength: {
                        value: VALIDATIONS.ADDRESS_MAX,
                        message: VALIDATIONS_TEXT.ADDRESS_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.ADDRESS_REQUIRED,
                      },
                    })}
                  />
                  {errors.address && (
                    <small className="text-red">{errors.address.message}</small>
                  )}
                </Form.Group> */}
              </Row>

              <Row>
                <Col md={6}>
                  <div class="form-floating">
                    <select
                      class="form-select"
                      id="floatingSelect"
                      aria-label="Floating label select example"
                      {...register("country", {
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.COUNTRY_NAME_REQUIRED,
                        },
                      })}
                    >
                      {options.map((data) => (
                        <option key={data.value} value={data.value}>
                          {data.label}
                        </option>
                      ))}
                    </select>
                    <label for="floatingSelect">Country</label>
                  </div>
                </Col>
                {/* <Form.Group
                  as={Col}
                  controlId="formGridState"
                  className="form-group"
                >
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    // defaultValue={options[0]}
                    {...register("country", {
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.COUNTRY_NAME_REQUIRED,
                      },
                    })}
                  >
                    {options.map((data) => (
                      <option key={data.value} value={data.value}>
                        {data.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group> */}

                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label="City">
                    <Form.Control
                      type="text"
                      placeholder="New York"
                      maxLength={VALIDATIONS.CITY_NAME}
                      onKeyDown={(e) =>
                        SPECIAL_CHARACTER_NUMBER_ARRAY.includes(e.key) &&
                        e.preventDefault()
                      }
                      {...register("city", {
                        maxLength: {
                          value: VALIDATIONS.CITY_NAME,
                          message: VALIDATIONS_TEXT.CITY_MAX,
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message:
                            VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_CITY,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.CITY_NAME_REQUIRED,
                        },
                      })}
                    />
                    {errors.city && (
                      <small className="text-red">{errors.city.message}</small>
                    )}
                  </FloatingLabel>
                </Col>
                {/* 
                <Form.Group
                  as={Col}
                  controlId="formGridEmail"
                  className="form-group"
                >
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="New York"
                    maxLength={VALIDATIONS.CITY_NAME}
                    onKeyDown={(e) =>
                      SPECIAL_CHARACTER_NUMBER_ARRAY.includes(e.key) &&
                      e.preventDefault()
                    }
                    {...register("city", {
                      maxLength: {
                        value: VALIDATIONS.CITY_NAME,
                        message: VALIDATIONS_TEXT.CITY_MAX,
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]*$/,
                        message:
                          VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_CITY,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.CITY_NAME_REQUIRED,
                      },
                    })}
                  />
                  {errors.city && (
                    <small className="text-red">{errors.city.message}</small>
                  )}
                </Form.Group> */}
              </Row>

              <Row>
                <Col md={6}>
                <FloatingLabel controlId="floatingInput" label="State">
                    <Form.Control
                      type="text"
                      placeholder="Los Angeles"
                      maxLength={VALIDATIONS.STATE}
                      onKeyDown={(e) =>
                        SPECIAL_CHARACTER_NUMBER_ARRAY.includes(e.key) &&
                        e.preventDefault()
                      }
                      {...register("state", {
                        maxLength: {
                          value: VALIDATIONS.STATE,
                          message: VALIDATIONS_TEXT.STATE_MAX,
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message:
                            VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_STATE,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.STATE_REQUIRED,
                        },
                      })}
                    />
                    {errors.state && (
                      <small className="text-red">{errors.state.message}</small>
                    )}
                  </FloatingLabel>
                </Col>

                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label="Zip Code">
                    <Form.Control
                      type="tel"
                      placeholder="12345"
                      onKeyDown={(e) => {
                        EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                          e.key.toUpperCase()
                        ) && e.preventDefault();
                      }}
                      maxLength={VALIDATIONS.ZIP_CODE}
                      {...register("zip_code", {
                        pattern: {
                          value: /^\d+$/,
                          message:
                            VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_ZIP,
                        },
                        maxLength: {
                          value: VALIDATIONS.ZIP_CODE,
                          message: VALIDATIONS_TEXT.ZIP_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.ZIP_CODE_REQUIRED,
                        },
                      })}
                    />
                    {errors.zip_code && (
                      <small className="text-red">
                        {errors.zip_code.message}
                      </small>
                    )}
                  </FloatingLabel>
                </Col>
              </Row>

              {/* <Form.Group
                as={Col}
                controlId="formGridEmail"
                className="form-group"
              >
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="12345"
                  onKeyDown={(e) => {
                    EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                      e.key.toUpperCase()
                    ) && e.preventDefault();
                  }}
                  maxLength={VALIDATIONS.ZIP_CODE}
                  {...register("zip_code", {
                    pattern: {
                      value: /^\d+$/,
                      message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_ZIP,
                    },
                    maxLength: {
                      value: VALIDATIONS.ZIP_CODE,
                      message: VALIDATIONS_TEXT.ZIP_MAX,
                    },
                    required: {
                      value: true,
                      message: VALIDATIONS_TEXT.ZIP_CODE_REQUIRED,
                    },
                  })}
                />
                {errors.zip_code && (
                  <small className="text-red">{errors.zip_code.message}</small>
                )}
              </Form.Group> */}

              <div className="button-div">
                <Button type="submit">Save</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerAccountDetailsEdit;
