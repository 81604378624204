import React from "react";
import { Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { ModalCrossIcon, StarIcon, FullStarIcon, HalfStarIcon } from "../../utils/svg";
import ReactStars from "react-rating-stars-component";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { render } from "react-dom";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";


const ReviewModal = (props) => {
    const secondExample = {
      size: 50,
      count: 5,
      color: "red",
      activeColor: "yellow",
      value: 0,
      a11y: true,
      isHalf: false,
      emptyIcon: <StarIcon />,
      halfIcon: <HalfStarIcon />,
      filledIcon: <FullStarIcon />,
      onChange: (newValue) => {
        console.log(`Example 2: new value is ${newValue}`);
      },
    };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    props.onHide();
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="review-order-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4>Add Review</h4>
          </Modal.Title>
          <div className="close-div">
            <Button onClick={props.onHide}>
              <ModalCrossIcon />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="main-rating-div">
            <div className="review-heading">
              <h4>Your Ratings:</h4>
            </div>
            <div className="start-rating-section">
              <div class="rating-box">
                {/* <ReactStars count={5} size={24} color2={"#ffd700"} /> */}
                <ReactStars {...secondExample} />
              </div>
            </div>
          </div>
          <div className="buyer-contact-fields">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6}>
                  <FloatingLabel controlId="floatingInput" label="Name">
                    <Form.Control
                      placeholder="Name"
                      type="text"
                      onKeyDown={(e) =>
                        EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                        e.preventDefault()
                      }
                      maxLength={VALIDATIONS.NAME}
                      {...register("name", {
                        maxLength: {
                          value: VALIDATIONS.NAME,
                          message: VALIDATIONS_TEXT.NAME_MAX,
                        },
                        pattern: {
                          value: /^[a-zA-Z\s]*$/,
                          message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.NAME_REQUIRED,
                        },
                      })}
                    />
                    {errors.name && (
                      <small className="text-red">{errors.name.message}</small>
                    )}
                  </FloatingLabel>
                </Col>

                <Col md={6}>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email Address"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      maxLength={VALIDATIONS.EMAIL}
                      {...register("email", {
                        maxLength: {
                          value: VALIDATIONS.EMAIL,
                          message: VALIDATIONS_TEXT.EMAIL_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                        },
                        pattern: {
                          value:
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                          message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                        },
                      })}
                    />
                    {errors.email && (
                      <small className="text-red error_from">
                        {errors.email.message}
                      </small>
                    )}
                  </FloatingLabel>
                </Col>
              </Row>

              <Col md={12}>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Review"
                    id="floatingTextarea"
                  ></textarea>
                  <label for="floatingTextarea">Review</label>
                </div>
              </Col>

              <div className="button-div">
                <Button type="submit">Submit</Button>
              </div>
            </Form>
          </div>
          {/* <div className="submit-div">
            <Button >Submit</Button>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReviewModal;
