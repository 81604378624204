import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import { Assets } from "../../constant/images";
import { Col, Row } from "react-bootstrap";

const BuyerProductSlider = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <>
      <div className="product-slider">
        <Row>
            <Col md={2}>
            <div className="swiper-testing">
          <Swiper
          onSwiper={setThumbsSwiper}
          direction={"vertical"}
          loop={true}
          spaceBetween={10}
          slidesPerView={5}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src={props.subimg1} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.subimg2} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.subimg3} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.subimg4} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.subimg5} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.subimg6} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.subimg7} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.subimg8} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.subimg9} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.subimg10} />
          </SwiperSlide>
        </Swiper>
          </div>
            </Col>
            <Col md={10}>
            <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={false}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2"
        >
          <SwiperSlide>
            <img src={props.mainimg1} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.mainimg2} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.mainimg3} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.mainimg4} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.mainimg5} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.mainimg6} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.mainimg7} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.mainimg8} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.mainimg9} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={props.mainimg10} />
          </SwiperSlide>
        </Swiper>
            </Col>
        </Row>


      </div>


    </>
  );
};

export default BuyerProductSlider;
