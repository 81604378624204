import React, { useState } from "react";
import { Col, FloatingLabel, Row } from "react-bootstrap";
import { Assets } from "../../constant/images";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { SignupUpload } from "../../utils/svg";
import signupupload from "../../../src/assets/images/Auth/signupupload.png";
import { useForm } from "react-hook-form";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";
import { OpenIcon, CloseIcon } from "../../utils/svg";
import PhoneInput from "react-phone-number-input";
const EditMyProfile = () => {
  const [options, setOptions] = useState([
    { value: "0", label: "United States" },
    { value: "1", label: "England" },
    { value: "2", label: "Canada" },
  ]);
  const [value, setValue] = useState();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const password = watch("password");
  const navigate = useNavigate();
  const onSubmit = (data) => {
    navigate("/otpverify-account");
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [profilePic, setProfilePic] = useState({
    file: null,
    file_url: null,
  });
  return (
    <>
    <div className="all-profile-heading">My Profile</div>
    <div className="my-profile">
        <div className="profile-data">
        <div className="d-flex align-items-center justify-content-between">
            <div>
                <img className="img-fluid" src={Assets.ProfileImg}/>
            </div>
            <div>
                <NavLink to="/edit-my-profile"><Button className="edit-profile-btn">Edit Profile</Button></NavLink>
            </div>
        </div>
        <div className="edit-profile-heading">The Half Moon</div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">Your Name</div><div className="edit-profile-right-side-text ">John Doe</div>
        </div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">Email Address</div><div className="edit-profile-right-side-text ">thehalfmoon@gmail.com</div>
        </div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">Phone Number</div><div className="edit-profile-right-side-text ">+1-3467-4675778</div>
        </div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">Address</div><div className="edit-profile-right-side-text ">217 E Bando Street, New York, US</div>
        </div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">Country</div><div className="edit-profile-right-side-text ">United States</div>
        </div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">City</div><div className="edit-profile-right-side-text ">New York</div>
        </div>
        <div className="edit-profile-both-sides">
            <div className="edit-profile-left-side-text">Zipcode</div><div className="edit-profile-right-side-text ">12345</div>
        </div>
        </div>
    </div>
    </>
  );
};

export default EditMyProfile;
