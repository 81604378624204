import React from "react";
import { Assets } from "../../constant/images";
import {NavLink} from "react-router-dom";
const BuyerAccountDetails = () => {
  return (
    <>
      <div className="main-buyer-account-details">
        <div className="top-heading-edit-div">
          <div className="heading-div">
            <h4>Account Details</h4>
          </div>
          <div className="edit-div">
            <NavLink to="/account-details/buyer-account-details-edit">Edit</NavLink>
          </div>
        </div>

        <div className="buyer-account-content">
          <div className="buyer-avatar">
            <img className="img-fluid" src={Assets.BuyerAvatar} />
          </div>
          <div className="buyer-name">
            <h2>Sidney Wilams</h2>
          </div>
          <div className="buyer-info">
            <ul>
              <li>
                <span>Email Address:</span> <span>johnsmith@gmail.com</span>
              </li>
              <li>
                <span>Phone Number:</span> <span>+01-143553464</span>
              </li>
              <li>
                <span>Country:</span> <span>United States</span>
              </li>
              <li>
                <span>Address:</span> <span>217 E Bondo Street, New York</span>
              </li>
              <li>
                <span>City:</span> <span>New York</span>
              </li>
              <li>
                <span>State:</span> <span>Brooklyn</span>
              </li>
              <li>
                <span>Zipcode:</span> <span>12345</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerAccountDetails;
