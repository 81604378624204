import { React, useState } from "react";
import { Row, Col, Container, Button, Nav } from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import {
  ArrowRight,
  DeleteIcon,
  InStockIcon,
  NegativeIcon,
  PlusIcon,
  PositiveIcon,
  ProductRatingStar,
  SeemoreArrow,
  StarReviewIcon,
  WishlistIcon,
} from "../../constant/svg";
import ProductSlider from "../../components/ProductSlider/ProductSlider";
import InStock from "../../components/ProjectStatus/InStock";
import RatingReview from "../../components/RatingReview/RatingReview";
import DeleteModal from "../../components/Modals/DeleteModal";
import EditProductModal from "../../components/Modals/EditProductModal";
import Accordion from "react-bootstrap/Accordion";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProductCard from "../../components/ProductCard/ProductCard";
import ReviewCard from "../../components/ReviewCard/ReviewCard";
import { NavLink } from "react-router-dom";
import ThumbSlider from "../../components/ThumbSlider/ThumbSlider";
import BuyerProductSlider from "../../components/ProductSlider/BuyerProductSlider";
import AddtoCartModal from "../../components/Modals/AddtoCartModal";


const BuyerProductDetails = (props) => {
  const [deletemodalShow, setDeleteModalShow] = useState(false);
  const [editproductmodalshow, setEditProductModalShow] = useState(false);
  const [size, setSize] = useState("S");
  const [color, setColor] = useState("");
  const [wishlist, setWishlist] = useState(false);
  const [count, setCount] = useState(1);
  const [addcart,setAddcart] = useState(false);

  const incrementCount = () => {
    setCount(count + 1);
  };
  const decrementCount = () => {
    if (count > 0) setCount(count - 1);
  };

  const LabelComponent = (props) => {
    return (
      <div className="color-btn" style={{ backgroundColor: props.color }}></div>
    );
  };

  return (
    <>
      <div className="buyer-product-details">
        <Container>
          <div className="product-bg">
            <Row>
              <Col xl={5} lg={6} md={6} sm={12}>
              <div className="d-none d-lg-block">
              <BuyerProductSlider
                  mainimg1={Assets.wallclock}
                  mainimg2={Assets.wallclock}
                  mainimg3={Assets.wallclock}
                  mainimg4={Assets.wallclock}
                  mainimg5={Assets.wallclock}
                  mainimg6={Assets.wallclock}
                  mainimg7={Assets.wallclock}
                  mainimg8={Assets.wallclock}
                  mainimg9={Assets.wallclock}
                  mainimg10={Assets.wallclock}
                  subimg1={Assets.clockone}
                  subimg2={Assets.clocktwo}
                  subimg3={Assets.clockthree}
                  subimg4={Assets.clockfour}
                  subimg5={Assets.clockfive}
                  subimg6={Assets.clocktwo}
                  subimg7={Assets.clockthree}
                  subimg8={Assets.clockfour}
                  subimg9={Assets.clocktwo}
                  subimg10={Assets.clockthree}
                />
              </div>
              <div className="d-lg-none">
              <ProductSlider
                  mainimg1={Assets.wallclock}
                  mainimg2={Assets.wallclock}
                  mainimg3={Assets.wallclock}
                  mainimg4={Assets.wallclock}
                  mainimg5={Assets.wallclock}
                  mainimg6={Assets.wallclock}
                  mainimg7={Assets.wallclock}
                  mainimg8={Assets.wallclock}
                  mainimg9={Assets.wallclock}
                  mainimg10={Assets.wallclock}
                  subimg1={Assets.clockone}
                  subimg2={Assets.clocktwo}
                  subimg3={Assets.clockthree}
                  subimg4={Assets.clockfour}
                  subimg5={Assets.clockfive}
                  subimg6={Assets.clocktwo}
                  subimg7={Assets.clockthree}
                  subimg8={Assets.clockfour}
                  subimg9={Assets.clocktwo}
                  subimg10={Assets.clockthree}
                />
              </div>
              </Col>
              <Col xl={4} lg={6} md={6} sm={12}>
                <div className="product-data side-border">
                  <div className="buyer-product-title">
                    Black Minimalist Wall Clock
                  </div>
                  <div className="size">
                    Size: <b style={{ color: "black" }}>{size}</b>
                  </div>
                  <div className="sizes-btns">
                    <Form>
                      <Form.Check
                        onClick={() => {
                          setSize("XS");
                        }}
                        type="radio"
                        id="onea"
                        label="XS"
                        name="size"
                      />
                      <Form.Check
                        onClick={() => {
                          setSize("S");
                        }}
                        type="radio"
                        id="twob"
                        label="S"
                        name="size"
                      />
                      <Form.Check
                        onClick={() => {
                          setSize("M");
                        }}
                        type="radio"
                        label="M"
                        id="threec"
                        name="size"
                      />
                      <Form.Check
                        onClick={() => {
                          setSize("L");
                        }}
                        type="radio"
                        label="L"
                        id="fourd"
                        name="size"
                      />
                      <Form.Check
                        onClick={() => {
                          setSize("XL");
                        }}
                        type="radio"
                        label="XL"
                        id="fivee"
                        name="size"
                      />
                    </Form>
                  </div>

                  <div className="size">
                    Color: <b style={{ color: "black" }}>{color}</b>
                  </div>
                  <div className="color-btns">
                    <Form>
                      <Form.Check
                        onClick={() => {
                          setColor("Yellow");
                        }}
                        type="radio"
                        id="colora"
                        label={<LabelComponent color="#DBBB51" />}
                        name="size"
                      />
                      <Form.Check
                        onClick={() => {
                          setColor("Red");
                        }}
                        type="radio"
                        id="colorb"
                        label={<LabelComponent color="#DB4B4B" />}
                        name="size"
                      />
                      <Form.Check
                        onClick={() => {
                          setColor("Green");
                        }}
                        type="radio"
                        label={<LabelComponent color="#42C4AC" />}
                        id="colorc"
                        name="size"
                      />
                      <Form.Check
                        onClick={() => {
                          setColor("Purple");
                        }}
                        type="radio"
                        label={<LabelComponent color="#8352EC" />}
                        id="colord"
                        name="size"
                      />
                      <Form.Check
                        onClick={() => {
                          setColor("Orange");
                        }}
                        type="radio"
                        label={<LabelComponent color="#EE9543" />}
                        id="colore"
                        name="size"
                      />
                    </Form>
                  </div>

                  {/* product price section starts here */}
                  <div className="d-flex mt_20">
                    <div className="product-discount-price">$ 150.45</div>
                    <div className="product-original-price">
                      <strike>$150.45</strike>
                    </div>
                  </div>
                  {/* product price section ends here */}

                  {/* In stock section starts here */}
                  <div className="d-flex mt_20 align-items-center">
                    <div>
                      <Button className="stock-btn">
                        <InStockIcon />
                        In Stock
                      </Button>
                    </div>

                    <div>
                      <Button
                        onClick={() => {
                          setWishlist(!wishlist);
                        }}
                        className={`wishlist-btn ps_20 ${
                          wishlist ? "added" : ""
                        }`}
                      >
                        <div className="wishlist-icon-container">
                          <WishlistIcon />
                        </div>
                        {!wishlist ? <span>Wishlist</span> : <span>Added</span>}
                      </Button>
                    </div>
                  </div>
                  {/* In stock section ends here */}

                  {/* made in section starts here */}
                  <div className="made-in-section d-flex align-items-center justify-content-between">
                    <div>
                      <div className="heading">Origin</div>
                      <div>USA</div>
                    </div>
                    <div className="seperator-line"></div>
                    <div>
                      <div className="heading">Ratings</div>
                      <div className="d-flex align-items-center">
                        <ProductRatingStar />
                        <div className="buyer-product-rating">
                          4.0<span className="reviews-num">(225)</span>
                        </div>
                      </div>
                    </div>
                    <div className="seperator-line"></div>
                    <div>
                      <div className="heading">No. of Orders</div>
                      <div>360</div>
                    </div>
                  </div>
                  {/* made in section ends here */}

                  {/* Quantity Section start here */}
                  <div className="quantity-section">
                    <div className="quantity-heading">Quantity:</div>
                    <div>
                      <Row>
                        <Col md={4} lg={4}>
                          <div className="quantity-btn mt-0">
                            <div className="cursor-link" onClick={decrementCount}>
                              <NegativeIcon />
                            </div>
                            <div className="count-text">{count}</div>
                            <div className="cursor-link" onClick={incrementCount}>
                              <PositiveIcon />
                            </div>
                          </div>
                        </Col>
                        <Col md={6} lg={6}>
                          <div>
                            <Button onClick={() => setAddcart(true)} className="add-to-cart-btn">
                              Add to Cart
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* QUantity Section End here */}
                </div>
              </Col>
              <Col xl={3} lg={12} sm={12}>
                <div className="product-details-accordion">
                  <Accordion defaultActiveKey={["0"]} alwaysOpen>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <div className="acc-heading">Shipping</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <span className="conditions">Shipping Charges:</span>
                          <span
                            className="conditions"
                            style={{ color: "black" }}
                          >
                            $10 worldwide
                          </span>
                        </div>
                        <div className="mt_10">
                          <span className="conditions">Shipping From:</span>
                          <span
                            className="conditions"
                            style={{ color: "black" }}
                          >
                            Texas
                          </span>
                        </div>
                        <div className="mt_10">
                          <span className="conditions">Delivery:</span>
                          <span
                            className="conditions"
                            style={{ color: "black" }}
                          >
                            4-5 Business days
                          </span>
                        </div>
                        <div>
                          <hr />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="acc-heading">Product Details</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <span className="conditions">Material:</span>
                          <span
                            className="conditions"
                            style={{ color: "black" }}
                          >
                            Cotton 99%
                          </span>
                        </div>
                        <div>
                          <span className="conditions">SKU:</span>
                          <span
                            className="conditions"
                            style={{ color: "black" }}
                          >
                            CJ9256837
                          </span>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <div className="acc-heading">Exchange Return</div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <span className="conditions">
                            This is dummy copy. It is not meant to be read. It
                            has been placed here solely to demonstrate the look
                            and feel of finished, typeset text. He who searches
                            for meaning here will be sorely disappointed.
                          </span>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="message-seller">
                  <NavLink to="/buyer-product-listing">
                  <div className="d-flex align-items-center">
                    <div className="img-box">
                      <img src={Assets.msgseller} className="img-fluid" />
                    </div>
                    <div className="ps-2">
                      <div className="halfmoon-title">The Half Moon</div>
                      <div className="halfmoon-title">
                        owner of <span>The Half Moon</span>
                      </div>
                    </div>
                  </div>
                  </NavLink>
                  <div>
                    <Button className="seller-btn">Message Seller</Button>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <div className="mt_30">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col md={3}>
                      <div className="tab-btn-container">
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="first">Description</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Reviews (19)</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </Col>
                    <Col md={9}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div>
                            <div className="tabs-heading">Description</div>
                            <div className="tabs-para">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries,
                            </div>
                            <div className="tabs-heading mt_30">
                              Preparation and Usage
                            </div>
                            <div className="tabs-para">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.g software like Aldus PageMaker including
                              versions of Lorem Ipsum.
                            </div>
                            <div className="tabs-heading  mt_30">
                              Recycling Information
                            </div>
                            <div className="tabs-para">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.g software like Aldus PageMaker including
                              versions of Lorem Ipsum.
                            </div>
                            <div className="tabs-heading  mt_30">
                              Other Information
                            </div>
                            <div className="tabs-para">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.g software like Aldus PageMaker including
                              versions of Lorem Ipsum.{" "}
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <Col md={12}>
                            {/* Rating And Reviews Section Starts Here */}
                            <div className="rating-and-review-section p-0 m-0">
                              <div className="tabs-heading ">
                                Rating & Reviews
                              </div>
                              <div className="d-flex align-items-center">
                                <div>
                                  <StarReviewIcon />
                                </div>
                                <div className="star-reviews">
                                  4.9 (2130 reviews)
                                </div>
                              </div>
                              {/* Card 1 Starts Here */}
                              <ReviewCard
                                image={Assets.Kate}
                                Author="Kate Carter"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
        This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 1 Ends Here */}

                              {/* Card 2 Starts Here */}
                              <ReviewCard
                                image={Assets.Kelly}
                                Author="Kelly Jones"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 2 Ends Here */}

                              {/* Card 3 Starts Here */}
                              <ReviewCard
                                image={Assets.Carly}
                                Author="Carly West"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 3 Ends Here */}
                                                            {/* Card 3 Starts Here */}
                                                            <ReviewCard
                                image={Assets.Carly}
                                Author="Carly West"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 3 Ends Here */}
                                                            {/* Card 3 Starts Here */}
                                                            <ReviewCard
                                image={Assets.Carly}
                                Author="Carly West"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 3 Ends Here */}
                                                            {/* Card 3 Starts Here */}
                                                            <ReviewCard
                                image={Assets.Carly}
                                Author="Carly West"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 3 Ends Here */}
                            </div>{" "}
                            {/* Rating And Reviews Section Ends Here */}
                          </Col>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Row>

            <div className="more-product-section">
              <div className="d-block d-sm-flex align-items-center justify-content-between">
                <div className="more-heading">More from this shop</div>
                <NavLink to="/product-listing">
                <div className="see-more">
                  See more
                  <span className="ps-2">
                    <SeemoreArrow />
                  </span>
                </div>
                </NavLink>
              </div>
              <Row>
                <Col xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Bag}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />
                </Col>
                <Col xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Dog}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />{" "}
                </Col>
                <Col xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Tree}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />{" "}
                </Col>
                <Col xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Ball}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />{" "}
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <ProductCard
                    className="mt_30"
                    productimg={Assets.one}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />
                </Col>
                <Col md={6}>
                  <ProductCard
                    productimg={Assets.two}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />
                </Col>
              </Row>
              <Row>
                <Col  xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Bag}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />
                </Col>
                <Col  xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Dog}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />{" "}
                </Col>
                <Col  xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Tree}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />{" "}
                </Col>
                <Col  xs={12} md={6} sm={6} lg={3} xl={3}>
                  <ProductCard
                    productimg={Assets.Ball}
                    price="$122"
                    originalprice="$154"
                    desc="Lorem Ipsum is simply dummy text of the printing and of the typesetting industry."
                  />{" "}
                </Col>
              </Row>
            </div>
          </div>
          <DeleteModal
            heading="Delete Product"
            type="delete this product"
            btntext="Delete"
            link="/products/product-details"
            display="onClick={()=>{props.onHide}}"
            show={deletemodalShow}
            onHide={() => setDeleteModalShow(false)}
          />
          <AddtoCartModal
            show={addcart}
            onHide={() => setAddcart(false)}
          />
        </Container>
      </div>
    </>
  );
};

export default BuyerProductDetails;
