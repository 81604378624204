import logo from './logo.svg';
// import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/all.css";
import "./components/navigation/Navigation.jsx"
import Navigation from './components/navigation/Navigation.jsx';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useEffect } from 'react';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <Navigation />
    </BrowserRouter>    
    </div>
  );
}

export default App;
