import React, { useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import { ArrowRight, BackArrow } from "../../constant/svg";
import { NavLink, useNavigate } from "react-router-dom";
import RejectModal from "../../components/Modals/RejectModal";
import CancelOrderModal from "../../components/Modals/CancelOrderModal";
import { ToastContainer, toast } from "react-toastify";

const OrderDetails = () => {
  const [rejectmodalShow, setRejectModalShow] = useState(false);
  const [cancelModalShow, setCancelModalShow] = useState(false);

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setTimeout(() => {
      navigate("/checkout-screen2");
    }, 1500);
  };

  const OTPToast = () => {
    return (
      <div className=" toast-notif d-flex align-items-center">
        <div>
          <img src={Assets.ToastCheck} />
        </div>
        <div className="pl_15">
          <div className="main-toast-heading"> Success!</div>
          <div className="toast-subheading">
            {" "}
            Order accepted, let's deliver it on time!
          </div>
        </div>
      </div>
    );
  };

  const notify = () => toast(<OTPToast />);

  const columns = [
    {
      field: "UserId",
      headerName: "Product Title",
      width: 150,
      align: "center",
      headerAlign: "start",
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center mt-2">
            <img className="img-fluid" src={params.row.image} />
            <div className="product-table-title">{params.row.producttitle}</div>
          </div>
        );
      },
      sortable: false,
    },
    {
      field: "qty",
      headerName: "Quantity",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="order-detail-table-text">{params.row.qty}</div>
        );
      },
      sortable: false,
    },
    {
      field: "price",
      headerName: "Unit Price",
      width: 200,
      align: "start",
      headerAlign: "start",
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="order-detail-table-text">{params.row.price}</div>
        );
      },
    },
    {
      field: "total",
      headerName: "Total",
      width: 150,
      align: "start",
      headerAlign: "start",
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="order-detail-table-text">{params.row.total}</div>
        );
      },
    },
  ];

  const rows = [
    {
      id: 1,
      image: Assets.Tableavatarone,
      producttitle: "RW5562",
      qty: "2",
      projectdate: "Mar 29, 2022",
      status: <Dispatched />,
      price: "$120.00",
      total: "$400.00",
    },

    {
      id: 2,
      image: Assets.Tableavatartwo,
      producttitle: "RW5562",
      UserId: "02",
      qty: "1",
      projectdate: "Mar 29, 2022",
      status: <InProcess />,
      price: "$120.00",
      total: "$400.00",
    },

    {
      id: 3,
      image: Assets.Tableavatarthree,
      producttitle: "RW5562",
      UserId: "03",
      qty: "2",
      projectdate: "Mar 29, 2022",
      status: <Completed />,
      price: "$120.00",
      total: "$400.00",
    },
    {
      id: 4,
      price: "Sub Total",
      total: "$601.00",
    },
    {
      id: 5,
      price: "VAT(0%)",
      total: "$0.00",
    },
    {
      id: 6,
      price: "Shipping Rate",
      total: <div><div className="">(Free)</div><div className="order-est-delivery-time">(Est. delivery 15-17 Oct)</div></div>,
    },
    {
      id: 7,
      price: <b>Total</b>,
      total: <b>$606.00</b>,
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center">
        <NavLink to="/orders">
          <BackArrow />
        </NavLink>
        <div className="main-subheading">Order Details</div>
      </div>
      <div>
        <Row>
          <Col md={4}>
            <div className="orderno-bg">
              <h2>
                Order ID <span className="order-no">#RW5562</span>
              </h2>
              <div className="order-data">
                <Row>
                  <Col md={6}>
                    <div className="order-ques">Order Date: </div>
                  </Col>
                  <Col md={6}>
                    <div className="order-ans">Mar 22, 2022</div>
                  </Col>
                </Row>
              </div>
              <div className="order-data">
                <Row>
                  <Col md={6}>
                    <div className="order-ques">Order Time: </div>
                  </Col>
                  <Col md={6}>
                    <div className="order-ans">14:06</div>
                  </Col>
                </Row>
              </div>
              <div className="order-data">
                <Row>
                  <Col md={6}>
                    <div className="order-ques">Name: </div>
                  </Col>
                  <Col md={6}>
                    <div className="order-ans">Sams Andrews</div>
                  </Col>
                </Row>
              </div>
              <div className="order-data">
                <Row>
                  <Col md={6}>
                    <div className="order-ques">Shipping Address: </div>
                  </Col>
                  <Col md={6}>
                    <div className="order-ans">
                      Street 24g, Flat no. 345, newyork city.
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="order-data">
                <Row>
                  <Col md={6}>
                    <div className="order-ques">Billing Address: </div>
                  </Col>
                  <Col md={6}>
                    <div className="order-ans">
                      Street 22g, Flat no. 204, newyork city.
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="order-data">
                <Row>
                  <Col md={6}>
                    <div className="order-ques">Status: </div>
                  </Col>
                  <Col md={6}>
                    <div className="order-ans">
                      <InProcess />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={8}>
            <div className="order-table order-detail-table mt-3 mt-md-0">
              <div style={{ height: 500, width: "100%" }}>
                <DataGrid
                  className="datagrid-table"
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[7]}
                  checkboxSelection
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <Button
                onClick={() => setCancelModalShow(true)}
                className="reject-btn"
              >
                Reject
              </Button>
              <Button onClick={notify} className="accept-btn">
                Accept Order
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <RejectModal
        heading="Reject Order"
        type="reject this order?"
        btntext="Reject"
        link="/products/product-details"
        display="onClick={()=>{props.onHide}}"
        show={rejectmodalShow}
        onHide={() => setRejectModalShow(false)}
      />

      <CancelOrderModal
        show={cancelModalShow}
        onHide={() => setCancelModalShow(false)}
      />
      <ToastContainer hideProgressBar={true} />
    </>
  );
};

export default OrderDetails;
