import React from 'react'
import { Assets } from '../../constant/images';
import {NavLink} from "react-router-dom"
import { CategoryIcon, ChatIcon, ChatIcon2, DashboardIcon, InventoryIcon, OrderIcon, ProductsIcon } from '../../constant/svg';

const DashboardSidebar = () => {
  return (
    <div className="dashboard-sidebar d-none d-lg-block"> 
        <div className='logo-container'><img src={Assets.Logo} /></div>

        <ul>
        <li className="mt_10">
          <NavLink to="/dashboard">
            <div className="nav-bg">
              <span>
                <DashboardIcon />
              </span>
              <span className="ps-3">Dashboard</span>
            </div>
          </NavLink>
        </li>
        <li className="mt_10">
          <NavLink to="/orders">
            <div className="nav-bg">
              <span>
                <OrderIcon />
              </span>
              <span className="ps-3">Orders</span>
            </div>
          </NavLink>
        </li>
        <li className="mt_10">
          <NavLink to="/category/category-table">
            <div className="nav-bg">
              <span>
                <CategoryIcon />
              </span>
              <span className="ps-3">Category</span>
            </div>
          </NavLink>
        </li>
        <li className="mt_10">
          <NavLink to="/products/list">
            <div className="nav-bg">
              <span>
                <ProductsIcon />
              </span>
              <span className="ps-3">Products</span>
            </div>
          </NavLink>
        </li>
        <li className="mt_10">
          <NavLink to="/inventory">
            <div className="nav-bg">
              <span>
                <InventoryIcon />
              </span>
              <span className="ps-3">Inventory</span>
            </div>
          </NavLink>
        </li>
        <li className="mt_10">
          <NavLink to="/chat">
            <div className="nav-bg">
              <span>
                <ChatIcon2 />
              </span>
              <span className="ps-3">Chat</span>
            </div>
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

export default DashboardSidebar