import React from "react";
import { Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { ModalCrossIcon } from "../../utils/svg";
import Form from 'react-bootstrap/Form';
const CancelOrderModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="cancel-order-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4> Reason for Cancellation</h4>
          </Modal.Title>
          <div className="close-div">
            <Button onClick={props.onHide}>
              <ModalCrossIcon />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className=""
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control as="textarea" placeholder="Write your reason here"/>
            </Form.Group>
          </Form>
          <div className="submit-div">
          <Button onClick={props.onHide}>Submit</Button>
          </div>

        </Modal.Body>

      </Modal>
    </>
  );
};

export default CancelOrderModal;
