import { React, useState } from "react";
import { Container } from "react-bootstrap";
import { Assets } from "../constant/images";
import { Row, Col, Button, Form } from "react-bootstrap";
import SelectRole from "./AuthComponents/SelectRole";
import OtpInput from "react-otp-input";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import SocialLogin from "./AuthComponents/SocialLogin";
import { OpenIcon, CloseIcon } from "../utils/svg";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../constant/app-constants";
import { useForm } from "react-hook-form";


const Signin = (props) => {

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const password = watch("password");
  const navigate = useNavigate();
  const onSubmit = (data) => {
    const {email,currentpassword} = data
    if(email === "buyer@afrotierre.com" ){
      navigate("/home")
    }
    else if(email ==="seller@afrotierre.com" ){
      navigate("/dashboard")
    }
    else{
      setError('email', { type: 'custom', message: 'Email not registered' });
    }
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  return (
    <>
      <h2>Sign In</h2>
      <h4>Welcome!</h4>
      <div className="new-hr">
        <hr />
      </div>
      <Form  onSubmit={handleSubmit(onSubmit)}>
      <div className="signup-form">
        <Row>
          <Col md={6}>
          <FloatingLabel
                    controlId="floatingInput"
                    label="Email Address"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      maxLength={VALIDATIONS.EMAIL}
                      {...register("email", {
                        maxLength: {
                          value: VALIDATIONS.EMAIL,
                          message: VALIDATIONS_TEXT.EMAIL_MAX,
                        },
                        required: {
                          value: true,
                          message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                        },
                        pattern: {
                          value:
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                          message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                        },
                      })}
                    />
                    {errors.email && (
                      <small className="text-red error_from">
                        {errors.email.message}
                      </small>
                    )}
                  </FloatingLabel>
          </Col>
          <Col md={6}>
          <FloatingLabel controlId="floatingInput" label="Password">
                  <Form.Control
                    type={!showCurrentPassword ? "password" : "text"}
                    placeholder="Enter your old password"
                    maxLength={VALIDATIONS_TEXT.PASSWORD_MAX}
                    {...register("currentpassword", {
                      minLength: {
                        value: VALIDATIONS.PASSWORD_MIN,
                        message: VALIDATIONS_TEXT.PASSWORD_MIN,
                      },
                      maxLength: {
                        value: VALIDATIONS.PASSWORD_MAX,
                        message: VALIDATIONS_TEXT.PASSWORD_MAX,
                      },
                      required: {
                        value: true,
                        message: VALIDATIONS_TEXT.PASSWORD_REQUIRED,
                      },
                      pattern: {
                        value: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
                        message: VALIDATIONS_TEXT.PASSWORD_FORMAT,
                      },
                    })}
                  />
                  {errors.currentpassword && (
                    <small className="text-red error_from error_from">
                      {errors.currentpassword.message}
                    </small>
                  )}

                  <span className="iconfeildauth">
                    {showCurrentPassword ? (
                      <span
                        className="toggle-password"
                        onClick={() => setShowCurrentPassword(false)}
                      >
                        <OpenIcon />
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowCurrentPassword(true)}
                        className="toggle-password"
                      >
                        <CloseIcon />
                      </span>
                    )}
                  </span>
                </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <div className="forgot-password">
            <NavLink to="/forgot-password">Forgot Password</NavLink>
          </div>
        </Row>
        <div>
          <Button type="submit" className="auth-btn">Sign In</Button>
        </div>
        <div className="new-hr mb-0">
          <hr className="mb-0" />
        </div>
        <SocialLogin />
        <div className="d-flex mt_30">
          <div className="already-account">Don't have an account?</div>
          <div className="already-acc-link">
            <NavLink to="/">SIGN Up</NavLink>
          </div>
        </div>
        <div className="as-guest">
        <NavLink to="/guest-home">Continue as a guest</NavLink>
        </div>
      </div>
      </Form>
      <div className="new-hr mb-0">
        <hr className="mb-0" />
      </div>
      <Row>
        <Col md={8}>
          <div className="privacy mt_20">
            By clicking Continue with Google, Facebook, or Apple, you agree to
            Afrotierre’s <NavLink to="/buyer-terms"><span>Terms and Conditions</span></NavLink> and{" "}
            <NavLink to="/buyer-privacy"><span>Privacy Policy</span></NavLink>
          </div>
          <div className="privacy mt_10 mb_20">
            Afrotierre may send you communications, you may change your
            preferences in your account settings. We'll never post without your
            permission.
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Signin;
