import {React,useState} from "react";
import { Col, Row , Container, Button, Form } from "react-bootstrap";
import PaymentCard from "../../components/Checkout/PaymentCard";
import { Assets } from "../../constant/images";
import { ToastContainer, toast } from 'react-toastify';
import CheckoutProducts from "../../components/Checkout/CheckoutProducts";
import CancelOrderModal from "../../components/Modals/CancelOrderModal";
import { AddNewCard } from "../../constant/svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";




const CheckoutScreenTwo = (props) => {
  const OTPToast = () =>{
    return(
      <div className=" toast-notif d-flex align-items-center">
      <div><img src={Assets.ToastCheck}/></div>
      <div className="pl_15">
        <div className="main-toast-heading"> Congratulations!</div>
        <div className="toast-subheading"> Thank you for purchasing. Your order will be shipped in 2-4 working days.</div>
      </div>
    </div>
    )
  }
  const notify = () => toast(<OTPToast/>);
  const [cancelModalShow,setCancelModalShow]=useState(false)




  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });


  const navigate = useNavigate();
  const onSubmit = async (data) => {
    notify()
      setTimeout(()=>{
        navigate("/order-summary")}, 1500)
    }
  return (


        <div className="checkout">
        <Container>
          <div className="checkout-main-heading">{props.mainheading}</div>
          <Row>
            <Col md={5} lg={6}>
            <div>
      <div>
        <div className="checkout-subheading">3. Review</div>
        <div className="review">
          <div className="shipping-heading">Shipping Address</div>
          <Row>
            <Col md={12}>
              <div className="profile-data">
                <div className="d-flex align-items-center mt_15">
                  <div className="data-ques">Name</div>
                  <div className="data-ans">John Doe</div>
                </div>
                <div className="d-flex align-items-center mt_15">
                  <div className="data-ques">Address</div>
                  <div className="data-ans">217 E Bando Street</div>
                </div>
                <div className="d-flex align-items-center mt_15">
                  <div className="data-ques">City</div>
                  <div className="data-ans">New York</div>
                </div>
                <div className="d-flex align-items-center mt_15">
                  <div className="data-ques">Country:</div>
                  <div className="data-ans">United States</div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="payment-heading">Payment Method</div>
          <div>
          <PaymentCard img={Assets.Mastercard} cardtitle="Mastercard" code="**** 1234"/>
          </div>
        </div>
      </div>
    </div>
            </Col>
            <Col md={7} lg={{span: 5, offset: 1}}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="total-items-container ">
                <div className="items-heading">Items (2) Total</div>
                <div className="subhr">
                  <hr />
                </div>
                <CheckoutProducts
                  checkouttitle="DiW_ Shop 24/7"
                  checkoutprice="USD $48"
                  qtyprice="$150.45"
                  qty="1"
                  cartproductimg={Assets.CartProduct2}
                />
                <CheckoutProducts
                  checkouttitle="DiW_ Shop 24/7"
                  checkoutprice="USD $48"
                  qtyprice="$150.45"
                  qty="1"
                  cartproductimg={Assets.CartProduct1}
                />
                <div className="total-item-checkout-section">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="item">Sub Total :</div>
                    <div className="total">$140</div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt_15">
                    <div className="item">Shipping:</div>
                    <div className="total">Free</div>
                  </div>
                  <div className="d-flex justify-content-end mt_15 est-time">
                    (Est time. 14-16 Nov)
                  </div>
                  <div className="totalhr">
                    <hr />
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt_15">
                    <div className="item" style={{ fontWeight: 600 }}>
                      Total Amount:
                    </div>
                    <div className="total" style={{ fontWeight: 600 }}>
                      $140.45
                    </div>
                  </div>
                  <div>
                    <Button type="submit" className="proceed-btn">{props.btn1txt}</Button>
                  </div>
                  <div  onClick={() => setCancelModalShow(true)}>
                    <NavLink to={props.link2}><Button className="continue-btn">{props.btn2txt}</Button></NavLink>
                  </div>
                </div>
              </div>
              </Form>
            </Col>
          </Row>
        </Container>
        <CancelOrderModal
            show={cancelModalShow}
            onHide={() => setCancelModalShow(false)}
          />
        
      </div>
  );
};

export default CheckoutScreenTwo;
