import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import {
  ArrowRight,
  BackPage,
  HiddenPagesNo,
  NextestPage,
  NextPage,
} from "../../constant/svg";
import { NavLink } from "react-router-dom";
import Select from "react-select";

const Dashboard = () => {
  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: "20px",
      textAlign: "left",
      paddingLeft: "10px",
      color: "red",
      fontSize: "14px",
      fontColor: "red",
      boxShadow: "none",
      fontFamily: "Ageo",
      fontSize: "14px",
      color: state.isSelected ? "red" : "yellow",
      border: state.isFocused ? "1px solid #dbbb51" : "1px solid #ccc",
      "&:hover": {
        borderColor: state.isFocused ? "#dbbb51" : "#dbbb51",
      },
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#707581",
        paddingLeft: "0",
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: "pointer",
        backgroundColor: isDisabled ? "red" : "white",
        fontFamily: "Ageo",
        fontSize: "14px",
        color: "#707581",
        fontSize: "14px",
        boxShadow: "none",
        textAlign: "left",
        ":hover": {
          backgroundColor: "#F4F4F4",
          color: "#dbbb51",
        },
      };
    },
  };

  const columns = [
    {
      field: "UserId",
      headerName: "Order ID",
      width: 120,
      align: "center",
      headerAlign: "start",
      renderCell: (params) => {
        return (
          <div className="d-flex align-items-center mt-2">
            <img className="img-fluid" src={params.row.image} />
            <div className="product-table-title">{params.row.producttitle}</div>
          </div>
        );
      },
    },
    {
      field: "fullname",
      headerName: "Customer Name",
      width: 230,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "projectdate",
      headerName: "Date",
      width: 230,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 230,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <div>{params.row.status}</div>;
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 230,
      align: "center",
      headerAlign: "center",
    },
  ];

  const rows = [
    {
      id: 1,
      image: Assets.Tableavatarone,
      producttitle: "RW5562",
      fullname: "Paul Doe",
      projectdate: "Mar 22, 2022  10:30",
      status: <Dispatched />,
      price: "$120.00",
    },

    {
      id: 2,
      image: Assets.Tableavatartwo,
      producttitle: "RW5562",
      UserId: "02",
      fullname: "John Doe",
      projectdate: "Mar 22, 2022  10:30",
      status: <InProcess />,
      price: "$120.00",
    },

    {
      id: 3,
      image: Assets.Tableavatarthree,
      producttitle: "RW5562",
      UserId: "03",
      fullname: "Ahmed",
      projectdate: "Mar 22, 2022  10:30",
      status: <Completed />,
      price: "$120.00",
    },
    {
      id: 4,
      image: Assets.Tableavatarfour,
      producttitle: "RW5562",
      UserId: "04",
      fullname: "Mikaal",
      projectdate: "Mar 22, 2022  10:30",
      status: <Dispatched />,
      price: "$120.00",
    },
    {
      id: 5,
      image: Assets.Tableavatarone,
      producttitle: "RW5562",
      UserId: "05",
      fullname: "Michael Fiz",
      projectdate: "Mar 22, 2022  10:30",
      status: <InProcess />,
      price: "$120.00",
    },
    {
      id: 6,
      image: Assets.Tableavatartwo,
      producttitle: "RW5562",
      UserId: "06",
      fullname: "Laura Michel",
      projectdate: "Mar 22, 2022  10:30",
      status: <Completed />,
      price: "$120.00",
    },
    {
      id: 7,
      image: Assets.Tableavatarthree,
      producttitle: "RW5562",
      UserId: "07",
      fullname: "Elijah William",
      projectdate: "Mar 22, 2022  10:30",
      status: <Dispatched />,
      price: "$120.00",
    },
    {
      id: 8,
      image: Assets.Tableavatarfour,
      producttitle: "RW5562",
      UserId: "08",
      fullname: "Laura Michel",
      projectdate: "Mar 22, 2022  10:30",
      status: <InProcess />,
      price: "$120.00",
    },
    {
      id: 9,
      image: Assets.Tableavatarone,
      producttitle: "RW5562",
      UserId: "09",
      fullname: "Elijah William",
      projectdate: "Mar 22, 2022  10:30",
      status: <Completed />,
      price: "$120.00",
    },
  ];
  return (
    <>
          <div className="dashboard-main-heading">Dashboard</div>

      <Row>
        <Col md={8} lg={8} xl={8} xxl={9}>
          <div className="patient-section">
            <Row>
              <Col md={12}>
                <div className="d-flex align-items-center justify-content-between pe-2 pe-sm-0">
                  <div className="containers-heading ps-3 ps-sm-0">
                    Total Revenue
                  </div>
                  {/* <div className="text-end dropdown-custom-btn px-2 px-sm-0">
                    <Form.Select aria-label="Default select example">
                      <option>2022</option>
                      <option value="1">2021</option>
                      <option value="2">2020</option>
                      <option value="3">2019</option>
                      <option value="4">2018</option>
                      <option value="5">2017</option>
                    </Form.Select>
                  </div> */}
                  <div className="dashboard-dropdown">
                    <Select
                      className=" dropdown-custom-btn normal w-100"
                      classNamePrefix="afrorierre"
                      isMenuOpen={true}
                      options={[
                        { value: "1", label: "2022" },
                        { value: "2", label: "2021" },
                        { value: "3", label: "2020" },
                        { value: "4", label: "2019" },
                      ]}
                      placeholder="2022"
                      name="reactSelectName"
                      // menuIsOpen={true}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={colourStyles}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <LineGraph />
          </div>
        </Col>
        <Col md={4} lg={4} xl={4} xxl={3}>
          <div className="donut-chart-container mt-4 mt-md-0">
            <Row>
              <Col md={12}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="containers-heading ps-2 ps-sm-0">Orders</div>
                  {/* <div className="text-end dropdown-custom-btn">
                    <Form.Select aria-label="Default select example">
                      <option>This Month</option>
                      <option value="1">Last Month</option>
                      <option value="2">Next Month</option>
                    </Form.Select>
                  </div> */}
                  <div className="month-dropdown">
                    <Select
                      className=" dropdown-custom-btn normal w-100"
                      classNamePrefix="afrorierre"
                      isMenuOpen={true}
                      options={[
                        { value: "1", label: "This Month" },
                        { value: "2", label: "Last Month" },
                      ]}
                      placeholder="This Month"
                      name="reactSelectName"
                      // menuIsOpen={true}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={colourStyles}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <DonutChart />
            <div className="mt_40">
              <div className="d-flex">
                <div className="dark-golden-legend"></div>
                <div className="legend-text">Completed</div>
              </div>
              <div className="d-flex mt-2">
                <div className="light-golden-legend"></div>
                <div className="legend-text">New</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="order-table-section">
            {/* Top heading section of table */}
            <div className="d-block d-sm-flex align-items-center justify-content-between">
              <div className="order-title">New Orders</div>
              <div className="d-flex align-items-center ps-3 pt-3">
                {/* <div className="text-end dropdown-custom-btn">
                  <Form.Select aria-label="Default select example">
                    <option>This Month</option>
                    <option value="1">Last Month</option>
                    <option value="2">Next Month</option>
                  </Form.Select>
                </div> */}
                                  <div className="month-dropdown">
                    <Select
                      className=" dropdown-custom-btn normal w-100"
                      classNamePrefix="afrorierre"
                      isMenuOpen={true}
                      options={[
                        { value: "1", label: "This Month" },
                        { value: "2", label: "Last Month" },
                      ]}
                      placeholder="This Month"
                      name="reactSelectName"
                      // menuIsOpen={true}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={colourStyles}
                    />
                  </div>
                <div className="ps-2">
                  <NavLink to="/orders">
                    <Button className="view-all-btn">
                      View All{" "}
                      <div className="ps-2">
                        <ArrowRight />
                      </div>
                    </Button>
                  </NavLink>
                </div>
              </div>
            </div>
            {/* Top heading section of table ends here */}
            <div className="table-container order-table">
              <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                  className="datagrid-table"
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[7]}
                  checkboxSelection
                />
              </div>
              <div className="pagination-border d-flex justify-content-end pb-4">
                <div className="seller-paginations d-flex pe-3">
                  <div className="page-box">
                    <BackPage />
                  </div>
                  <div className="page-box active">1</div>
                  <div className="page-box">
                    <HiddenPagesNo />
                  </div>
                  <div className="page-box">
                    <NextPage />
                  </div>
                  <div className="page-box">
                    <NextestPage />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
