import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Assets } from "../../constant/images";
import { InputGroup } from "react-bootstrap";
import {Row,Col} from "react-bootstrap"
import { Basket, CartIcon, DropdownIcon, Heart, HeartIcon, MenuIcon, SearchIcon } from "../../constant/svg";
import { NavLink } from "react-router-dom";

const ProductCard = (props) => {
  return (
    <NavLink to="/buyer-product-details">
    <div className="product-card-bg mb_30">
        <div className="img-container"><img className="w-100" src={props.productimg}/>
        <div className="bottom-left">
            <div className="price-tag">
                <div className="price">{props.price}</div><strike><div className="original-price">${props.originalprice}</div></strike>
            </div>
        </div>
        <div className="top-right">
          <NavLink to="/wishlist">
            <div className="icon-container"><HeartIcon/></div>
          </NavLink>
        </div>

        <div className="bottom-right">
        <div className="icon-container"><CartIcon/></div>
        </div>
        </div>
        <div className="product-desc">{props.desc}</div>
    </div>
    </NavLink>
  )
}

export default ProductCard