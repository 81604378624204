import {React,useState} from 'react'
import { Button, Col, Container, Dropdown, Form, Nav, Offcanvas, Row, Tab } from 'react-bootstrap'
import ProductListingCard from '../../components/ProductCard/ProductListingCard'
import ReviewCard from '../../components/ReviewCard/ReviewCard'
import { Assets } from '../../constant/images'
import { CategoryCrossIcon, FilterIcon, GridViewIcon, ListViewIcon, StarReviewIcon } from '../../constant/svg'
import ProductListingData from '../ProductListing/ProductListingData'

const BuyerProductListing = (props) => {
    const [productdata, setProductData] = useState(ProductListingData);
      const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onHandle = () =>{
    console.log(props.modalShow)
    if(props.modalShow === true){
      props.setModalShow(false);
      
    }
  }

  return (
    <div className="buyer-product-listing">
        <Container>
        <div className='seller-profile-card'>

        <Row>

            <Col md={8}>
            <div className='d-block d-md-flex align-items-center'>
            <div className="seller-img-container">
                    <img className="img-fluid" src={Assets.SellerImg}/>
                </div>
                <div className='data-section'>
                    <div className='seller-profile-title'>The Half Moon</div>
                    <div className='seller-location '>Houston, Texas, US</div>
                    <div className='d-flex align-items-center'>
                        <div className="no-of-product-sold">5,652 products sold</div><div>                              
                            <div className="d-flex align-items-center ps-3">
                                <div>
                                  <StarReviewIcon />
                                </div>
                                <div className="star-reviews">
                                  4.0 <span>(213)</span>
                                </div>
                              </div>
                              </div>
                    </div>
                </div>
            </div>
            </Col>
            <Col md={4}>
                <div className='text-start text-md-end mt-3 mt-md-0'>
                    <Button className="msg-seller-btn">Message Seller</Button>
                </div>
            </Col>
        </Row>
        </div>
        <div className='all-products-heading'>All Products</div>
        <div className="product-listing-section">
        <div className="d-block d-md-flex align-items-center justify-content-between mb_30">
              <div>
                <Button className="filter-btn" 
 onClick={handleShow}>
                  <span className="px-2">All Filters</span> <FilterIcon />
                </Button>
              </div>
              <div className="d-block d-md-flex mt-3 mt-md-0">
                <Dropdown className=" px-2">
                  <Dropdown.Toggle id="filter-dropdown">
                    Sort by: <b>Default Sorting</b>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>A-Z</Dropdown.Item>
                    <Dropdown.Item>Z-A</Dropdown.Item>
                    <Dropdown.Item>Top Sales</Dropdown.Item>
                    <Dropdown.Item>Top Rated</Dropdown.Item>
                    <Dropdown.Item>Price (High to Low)</Dropdown.Item>
                    <Dropdown.Item>Price (Low to High)</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <div className="d-flex align-items-center mt-3 mt-md-0">
                  <div className="view">View: </div>
                  <div>
                    <Button className="view-btn">
                      <GridViewIcon />
                    </Button>
                  </div>
                  <div>
                    <Button className="view-btn">
                      <ListViewIcon/>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div>
        <Row>
              <div className="mt_30">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col md={3}>
                      <div className="tab-btn-container mb-3 mb-md-0">
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="first">Products (58)</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Reviews (19)</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                    </Col>
                    <Col md={9}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                        <div>                        
                            <Row>
                            {
                                productdata.map((data,index)=>(
                                <ProductListingCard key={index} data={data} />
                                ))
                            }
                            </Row>
                        </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <Col md={12}>
                            {/* Rating And Reviews Section Starts Here */}
                            <div className="rating-and-review-section p-0 m-0">
                              <div className="tabs-heading ">
                                Rating & Reviews
                              </div>
                              <div className="d-flex align-items-center">
                                <div>
                                  <StarReviewIcon />
                                </div>
                                <div className="star-reviews">
                                  4.9 (2130 reviews)
                                </div>
                              </div>
                              {/* Card 1 Starts Here */}
                              <ReviewCard
                                image={Assets.Kate}
                                Author="Kate Carter"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
        This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 1 Ends Here */}

                              {/* Card 2 Starts Here */}
                              <ReviewCard
                                image={Assets.Kelly}
                                Author="Kelly Jones"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 2 Ends Here */}

                              {/* Card 3 Starts Here */}
                              <ReviewCard
                                image={Assets.Carly}
                                Author="Carly West"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 3 Ends Here */}
                                                            {/* Card 3 Starts Here */}
                                                            <ReviewCard
                                image={Assets.Carly}
                                Author="Carly West"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 3 Ends Here */}
                                                            {/* Card 3 Starts Here */}
                                                            <ReviewCard
                                image={Assets.Carly}
                                Author="Carly West"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 3 Ends Here */}
                                                            {/* Card 3 Starts Here */}
                                                            <ReviewCard
                                image={Assets.Carly}
                                Author="Carly West"
                                date="Sep 20, 2022"
                                rating="4.0"
                                review="
              This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text."
                              />
                              {/* Card 3 Ends Here */}
                            </div>{" "}
                            {/* Rating And Reviews Section Ends Here */}
                          </Col>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Row>
        </div>

        </Container>
        <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          className="filter-btn-offcanvas"
        >
          <Offcanvas.Body>
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="filter-heading">Filters</div>
                <div onClick={handleClose}>
                  <CategoryCrossIcon />
                </div>
              </div>
              <hr />
              <div className="filter-conditions-section">
                <div className="subheading">Sort By</div>
                <Form>
                  <Form.Check
                    type="checkbox"
                    id="capital"
                    label="A-Z"
                    name="capital"
                  />
                  <Form.Check
                    type="checkbox"
                    id="reverse"
                    label="Z-A"
                    name="reverse"
                  />
                  <Form.Check
                    type="checkbox"
                    label="Top Sales"
                    id="sales"
                    name="sales"
                  />
                  <Form.Check
                    type="checkbox"
                    label="Top Rated"
                    id="rated"
                    name="rated"
                  />
                </Form>
                <div className="subheading">Price</div>
                <Form>
                  <Form.Check
                    type="radio"
                    id="one"
                    label="Any Price"
                    name="one"
                  />
                  <Form.Check
                    type="radio"
                    id="two"
                    label="Under USD 25"
                    name="one"
                  />
                  <Form.Check
                    type="radio"
                    label="USD 25 to USD 50"
                    id="three"
                    name="one"
                  />
                  <Form.Check
                    type="radio"
                    label="USD 50 to USD 100"
                    id="four"
                    name="one"
                  />
                  <Form.Check
                    type="radio"
                    label="Over USD 100"
                    id="five"
                    name="one"
                  />
                  <Form.Check type="radio" label="Custom" id="six" name="one" />
                  <div className="custom">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="text" placeholder="High" />
                    </Form.Group>
                    <div className="to">To</div>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="text" placeholder="Low" />
                    </Form.Group>
                  </div>
                </Form>
                <div className="subheading">Origin</div>
                <Form>
                  <Form.Check
                    type="radio"
                    id="onea"
                    label="Anywhere"
                    name="one"
                  />
                  <Form.Check type="radio" id="twob" label="USA" name="one" />
                  <Form.Check
                    type="radio"
                    label="Custom"
                    id="threec"
                    name="one"
                  />
                  <div className="custom origin">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control type="text" placeholder="Made in" />
                    </Form.Group>
                  </div>
                </Form>
              </div>
            </div>
            <div className="btn-pos">
              <hr />
            </div>
            <div className="d-block d-sm-flex align-items-center justify-content-between">
              <div>
                <Button onClick={handleClose} className="cancel-btn">Cancel</Button>
              </div>
              <div>
                <Button onClick={handleClose} className="apply-btn mt-3 mt-sm-0">Apply</Button>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  )
}

export default BuyerProductListing