import React from 'react'

const PaymentCard =(props) => {
    return(
        <>
        <div className="payment-card d-flex align-items-center ">
            <div className="payment-card-img">
                <img src={props.img}/>
            </div>
            <div>
                <div className="payment-card-title">{props.cardtitle} <span className="ps-2">{props.status}</span></div>
                <div className="payment-code">{props.code}</div>
            </div>
        </div>
        </>
    )
}

export default PaymentCard