import React, { useState } from "react";
import { Col, FloatingLabel, Row } from "react-bootstrap";
import { Assets } from "../../constant/images";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { SignupUpload } from "../../utils/svg";
import signupupload from "../../../src/assets/images/Auth/signupupload.png";
import { useForm } from "react-hook-form";
import {
  SPECIAL_CHARACTER_ARRAY,
  SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
  VALIDATIONS,
  VALIDATIONS_TEXT,
  EXCLUDE_NUMBER_INPUT,
  EXCLUDE_ALPHABETS_SPECIAL_CHAR,
  SPECIAL_CHARACTER_NUMBER_ARRAY,
} from "../../constant/app-constants";
import { OpenIcon, CloseIcon } from "../../utils/svg";
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";

const MyProfile = () => {
  const [options, setOptions] = useState([
    { value: "0", label: "United States" },
    { value: "1", label: "England" },
    { value: "2", label: "Canada" },
  ]);
  const [value, setValue] = useState();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const EditProfileToast = () => {
    return (
      <div className=" toast-notif d-flex align-items-center">
        <div>
          <img src={Assets.ToastCheck} />
        </div>
        <div className="pl_15">
          <div className="main-toast-heading">Success!</div>
          <div className="toast-subheading">Your changes has been saved.</div>
        </div>
      </div>
    );
  };

  const password = watch("password");
  const notify = () => toast(<EditProfileToast />);

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    notify();

    setTimeout(() => {
      navigate("/edit-my-profile");
    }, 1500);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [profilePic, setProfilePic] = useState({
    file: null,
    file_url: null,
  });
  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <div className="all-profile-heading">My Profile</div>
      <div className="my-profile">
        <Row>
          {/* <Col md={3}>
          <div className="profile-data">
            <div className="image-container">
              <img src={Assets.ProfileImg} />
            </div>
            <div className="profile-title">The Half Moon</div>
            <div className="profile-occupation">Store Manager</div>
            <div className="profile-mail">thehalfmoon@gmail.com</div>
          </div>
        </Col> */}
          <Col md={12}>
            <div className="profile-form">
              <Row>
                <Col>
                  <div className="img-div mb-0">
                    <label htmlFor="img-upload" className="file-upload">
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        id="img-upload"
                        name="avatar"
                        className="d-none"
                        onChange={(e) =>
                          setProfilePic({
                            file: e.target.files[0],
                            file_url: URL.createObjectURL(e.target.files[0]),
                          })
                        }
                      />
                      <img
                        src={
                          profilePic.file_url
                            ? profilePic.file_url
                            : signupupload
                        }
                        alt=""
                        className="w-100 h-100 img-upload"
                      />
                    </label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <div className="signup-form mt_20">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col md={6}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Your Name"
                          >
                            <Form.Control
                              placeholder="FirstName"
                              type="text"
                              onKeyDown={(e) =>
                                EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                                e.preventDefault()
                              }
                              maxLength={VALIDATIONS.NAME}
                              {...register("fname", {
                                maxLength: {
                                  value: VALIDATIONS.NAME,
                                  message: VALIDATIONS_TEXT.NAME_MAX,
                                },
                                pattern: {
                                  value: /^[a-zA-Z\s]*$/,
                                  message:
                                    VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                                },
                                required: {
                                  value: true,
                                  message: VALIDATIONS_TEXT.FIRST_NAME_REQUIRED,
                                },
                              })}
                            />
                            {errors.fname && (
                              <small className="text-red">
                                {errors.fname.message}
                              </small>
                            )}
                          </FloatingLabel>
                        </Col>
                        <Col md={6}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Store Name"
                          >
                            <Form.Control
                              placeholder="Name"
                              type="text"
                              onKeyDown={(e) =>
                                EXCLUDE_NUMBER_INPUT.includes(e.key) &&
                                e.preventDefault()
                              }
                              maxLength={VALIDATIONS.STORE_NAME}
                              {...register("storename", {
                                maxLength: {
                                  value: VALIDATIONS.STORE_NAME,
                                  message: VALIDATIONS_TEXT.STORE_NAME_MAX,
                                },
                                pattern: {
                                  value: /^[a-zA-Z\s]*$/,
                                  message:
                                    VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS,
                                },
                                required: {
                                  value: true,
                                  message: VALIDATIONS_TEXT.STORE_NAME_REQUIRED,
                                },
                              })}
                            />
                            {errors.storename && (
                              <small className="text-red">
                                {errors.storename.message}
                              </small>
                            )}
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Email Address"
                          >
                            <Form.Control
                              type="email"
                              placeholder="Email Address"
                              maxLength={VALIDATIONS.EMAIL}
                              {...register("email", {
                                maxLength: {
                                  value: VALIDATIONS.EMAIL,
                                  message: VALIDATIONS_TEXT.EMAIL_MAX,
                                },
                                required: {
                                  value: true,
                                  message: VALIDATIONS_TEXT.EMAIL_REQUIRED,
                                },
                                pattern: {
                                  value:
                                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                  message: VALIDATIONS_TEXT.EMAIL_FORMAT,
                                },
                              })}
                            />
                            {errors.email && (
                              <small className="text-red error_from">
                                {errors.email.message}
                              </small>
                            )}
                          </FloatingLabel>
                        </Col>
                        <Col md={6}>
                          <div className="phone-input">
                            <PhoneInput
                              international
                              placeholder="Enter phone number"
                              value={value}
                              onChange={setValue}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Address"
                          >
                            <Form.Control
                              type="text"
                              placeholder="name@example.com"
                              {...register("address", {
                                required: {
                                  value: true,
                                  message: VALIDATIONS_TEXT.ADDRESS_REQUIRED,
                                },
                              })}
                            />
                            {errors.address && (
                              <small className="text-red error_from">
                                {errors.address.message}
                              </small>
                            )}
                          </FloatingLabel>
                        </Col>
                        <Col md={6}>
                          <div class="form-floating">
                            <select
                              class="form-select"
                              id="floatingSelect"
                              aria-label="Floating label select example"
                              {...register("country", {
                                required: {
                                  value: true,
                                  message:
                                    VALIDATIONS_TEXT.COUNTRY_NAME_REQUIRED,
                                },
                              })}
                            >
                              {options.map((data) => (
                                <option key={data.value} value={data.value}>
                                  {data.label}
                                </option>
                              ))}
                            </select>
                            <label for="floatingSelect">Country</label>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <FloatingLabel controlId="floatingInput" label="City">
                            <Form.Control
                              type="text"
                              placeholder="name@example.com"
                              {...register("city", {
                                required: {
                                  value: true,
                                  message: VALIDATIONS_TEXT.CITY_REQUIRED,
                                },
                              })}
                            />
                            {errors.city && (
                              <small className="text-red error_from">
                                {errors.city.message}
                              </small>
                            )}
                          </FloatingLabel>
                        </Col>
                        <Col md={6}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="State"
                          >
                            <Form.Control
                              type="text"
                              placeholder="name@example.com"
                              {...register("state", {
                                required: {
                                  value: true,
                                  message: VALIDATIONS_TEXT.STATE_REQUIRED,
                                },
                              })}
                            />
                            {errors.state && (
                              <small className="text-red error_from">
                                {errors.state.message}
                              </small>
                            )}
                          </FloatingLabel>
                        </Col>
                        <Col md={6}>
                          <FloatingLabel
                            controlId="floatingInput"
                            label="Zip Code"
                          >
                            <Form.Control
                              type="tel"
                              placeholder="12345"
                              onKeyDown={(e) => {
                                EXCLUDE_ALPHABETS_SPECIAL_CHAR.includes(
                                  e.key.toUpperCase()
                                ) && e.preventDefault();
                              }}
                              maxLength={VALIDATIONS.ZIP_CODE}
                              {...register("zip_code", {
                                pattern: {
                                  value: /^\d+$/,
                                  message:
                                    VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS_ZIP,
                                },
                                maxLength: {
                                  value: VALIDATIONS.ZIP_CODE,
                                  message: VALIDATIONS_TEXT.ZIP_MAX,
                                },
                                required: {
                                  value: true,
                                  message: VALIDATIONS_TEXT.ZIP_CODE_REQUIRED,
                                },
                              })}
                            />
                            {errors.zip_code && (
                              <small className="text-red">
                                {errors.zip_code.message}
                              </small>
                            )}
                          </FloatingLabel>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end">
                        <Button type="submit" className="update-btn">
                          Save
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MyProfile;
