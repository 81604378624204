import React from "react";
import { FloatingLabel, Nav } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { ModalCrossIcon } from "../../utils/svg";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import Select from "react-select";
import signupupload from "../../assets/images/Auth/uploadcategoryimg.png";


const AddCategoryComponent = () => {
    const [profilePic, setProfilePic] = useState({
      file: null,
      file_url: null,
    });
    const colourStyles = {
      control: (styles, state) => ({
        ...styles,
        backgroundColor: "white",
        borderRadius: "20px",
        textAlign: "left",
        paddingLeft: "10px",
        color: "red",
        fontSize: "14px",
        fontColor: "red",
        boxShadow: "none",
        color: state.isSelected ? "red" : "yellow",
        border: state.isFocused ? "1px solid #dbbb51" : "1px solid #ccc",
        "&:hover": {
          borderColor: state.isFocused ? "#dbbb51" : "#dbbb51",
        },
      }),
      placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#707581',
            paddingLeft:"0",
        }
    },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          cursor: "pointer",
          backgroundColor: isDisabled ? "red" : "white",
  
          color: "#707581",
          fontSize: "14px",
          boxShadow: "none",
          textAlign: "left",
          ":hover": {
            backgroundColor: "#F4F4F4",
            color: "#dbbb51",
          },
        };
      },
    };
    return (
      <div>
        <div className="category-parent-dropdown">
          <Select
            className=" dropdown-custom-btn normal w-100"
            classNamePrefix="afrorierre"
            isMenuOpen={true}
            options={[
              { value: "chocolate", label: <div className="parent-opt">Parent</div> },
              { value: "strawberry", label: <div className="sub-parent">Sub Category</div> },
              { value: "vanilla", label: <div className="sub-parent">Sub Category</div> },
              { value: "vanilla", label: <div className="sub-parent">Sub Category</div> },
              { value: "vanilla", label: <div className="sub-parent">Sub Category</div> },
              { value: "chocolate", label: <div className="parent-opt">Parent</div> },
              { value: "strawberry", label: <div className="sub-parent">Sub Category</div> },
              { value: "vanilla", label: <div className="sub-parent">Sub Category</div> },
              { value: "vanilla", label: <div className="sub-parent">Sub Category</div> },
              { value: "vanilla", label: <div className="sub-parent">Sub Category</div> },
  
            ]}
            placeholder="Parent"
            name="reactSelectName"
            // menuIsOpen={true}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={colourStyles}
          />
        </div>
        <div className="category-img-div">
                  <label htmlFor="img-upload" className="file-upload">
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      id="img-upload"
                      name="avatar"
                      className="d-none"
                      onChange={(e) =>
                        setProfilePic({
                          file: e.target.files[0],
                          file_url: URL.createObjectURL(e.target.files[0]),
                        })
                      }
                    />
                    <img
                      src={
                        profilePic.file_url ? profilePic.file_url : signupupload
                      }
                      alt=""
                      className="w-100 h-100 img-upload"
                    />
                  </label>
                </div>
      </div>
    );
  };

  export default AddCategoryComponent;
