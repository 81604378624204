import { React, useState } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  InputGroup,
  DropdownButton,
} from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";

import {
  ArrowRight,
  Attachment,
  SearchIconTopBar,
  SendMessage,
  VerticalDots,
} from "../../constant/svg";
import ReportModal from "../../components/Modals/ReportModal";
import ReactTextareaAutosize from "react-textarea-autosize";

const ChatEmpty = () => {
  const [showreportmodal, setShowReportModal] = useState(false);
  const [showreportaccountmodal, setShowReportAccountModal] = useState(false);
  return (
    <>
      <div className="main-title-heading">Chat</div>
      <Row>
        <Col md={4}>
          <div className="inbox-section">
            <div className="search-box">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <SearchIconTopBar />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search by name"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div>
            <div className="d-flex align-items-center justify-content-center h-100">
              <div>
                <img className="img-fluid" src={Assets.Nomsg} />
                <div className="no-msg d-block">
                  There are no inbox here yet.
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={8}>
          <div className="messages-section mt-4 mt-md-0">
            <div className="d-flex align-items-center justify-content-between">
              <div className="author-name">Sam Andrew</div>
              <div>
                <div className="vertical-btn">
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={<VerticalDots />}
                  >
                    <Dropdown.Item
                      // onClick={() => setShowEditNew(true)}
                      onClick={() => setShowReportModal(true)}
                    >
                      Report Chat
                    </Dropdown.Item>
                    <Dropdown.Item
                      // onClick={() => setShowDelete(true)}
                      onClick={() => setShowReportAccountModal(true)}
                    >
                      Report Account
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </div>
            <div>
              <hr />
            </div>
            <div className="messages-area"></div>
            <div className="message-position d-flex align-items-center justify-content-between">
              <div className="attachment-bg">
                <Attachment />
              </div>
              <div>
                {/* <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control
                    as="textarea"
                    rows={1}
                    placeholder="Write your message heradsae.."
                  />
                </Form.Group> */}
                <ReactTextareaAutosize
                className="custom-textarea"
                  minRows={3}
                  maxRows={6}
                  style={{height: '43px'}}
                  // defaultValue="Write your message heradsae.."
                  placeholder="Write your message here.."
                />
              </div>
              <div className="send-bg">
                <SendMessage />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ReportModal
        show={showreportmodal}
        onHide={() => setShowReportModal(false)}
        title="Report Chat"
      />
      <ReportModal
        show={showreportaccountmodal}
        onHide={() => setShowReportAccountModal(false)}
        title="Report Account"
      />
    </>
  );
};

export default ChatEmpty;
