import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import DonutChart from "../../components/DonutChart/DonutChart";
import LineGraph from "../../components/LineGraph/LineGraph";
import DashboardSidebar from "../../components/Sidebar/DashboardSidebar";
import TopBar from "../../components/TopBar/TopBar";
import { Dropdown } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import { Form } from "react-bootstrap";
import { Assets } from "../../constant/images";
import Dispatched from "../../components/ProjectStatus/Dispatched";
import InProcess from "../../components/ProjectStatus/InProcess";
import Completed from "../../components/ProjectStatus/Completed";
import { AddProductBtn, ArrowRight } from "../../constant/svg";
import { NavLink } from "react-router-dom";
import AddCategoryModal from "../../components/Modals/AddCategoryModal";
import { useState } from "react";

const AddCategory = () => {
  const [showcategorymodal,setShowCategoryModal]= useState(false)
  const columns = [
    {
      field: "UserId",
      headerName: "Product Title",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <NavLink to="/orders/order-detail">
            <div className="d-flex align-items-center mt-2">
              <img className="img-fluid" src={params.row.image} />
              <div className="product-table-title">
                {params.row.producttitle}
              </div>
            </div>
          </NavLink>
        );
      },
    },
    {
      field: "startdate",
      headerName: "Start Date",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "projectdate",
      headerName: "End Date",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Project Status",
      width: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return <div>{params.row.status}</div>;
      },
    },
    {
      field: "price",
      headerName: "Project Price",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
  ];

  const rows = [
  ];
  return (
    <>
    <div className="main-title-heading">Category Management</div>
      <div className="order-table-container table-content-center">
        <div style={{ height: 100, width: "100%" }}>
          <DataGrid
            className="datagrid-table"
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[7]}
            checkboxSelection
          />
        </div>
        <div className="d-flex justify-content-center align-items-center" style={{height:"100%", margin:"auto"}}>
            <div>
                <img className="img-fluid" src={Assets.NoProduct}/>
                <div className="noproduct-text">There are no categories here yet.</div>
                <div className="text-center"><NavLink to="/category/category-table"><Button className="add-product-btn"><span><AddProductBtn/></span> Add Category</Button></NavLink></div>
            </div>
        </div>
      </div>

    </>
  );
};

export default AddCategory;
