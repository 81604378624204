import {React,useEffect,useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { NavLink, useNavigate} from "react-router-dom";
import { Assets } from "../../constant/images";
import { CrossIcon, ProfileRightArrow } from "../../constant/svg";
import DeleteModal from "./DeleteModal";
import LogoutModal from "./LogoutModal";

function ProfileModal(props) {
  const [showlogoutmodal,setShowLogoutModal]= useState(false)
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if(props.modalShow == true)
  //   props.onHide();
  // }, [navigate]);

  const onHandle = () =>{
    console.log(props.modalShow)
    if(props.modalShow === true){
      props.setModalShow(false);
      
    }
  }
  return (
    <>
        <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="profile-modal"
    >
      <div className="d-flex align-items-center justify-content-between">
        <div className="modal-title">My Profile</div>
        <div className="cross-bg" onClick={props.onHide}>
          <CrossIcon />
        </div>
      </div>
      <div className="d-flex mt_20 align-items-center">
        <div className="image-container">
          <NavLink to="/my-profile">
          <img className="img-fluid" src={Assets.ProfileImg} />
          </NavLink>
        </div>
        <div>
          <div className="profile-title"><NavLink to="/my-profile" onClick={onHandle}>The Half Moon</NavLink></div>
          <div className="profile-occupation"><NavLink to="/my-profile" onClick={onHandle}>Store Manager</NavLink></div>
          <div className="profile-mail"><NavLink to="/my-profile" onClick={onHandle}>thehalfmoon@gmail.com</NavLink></div>
        </div>
      </div>
      <hr />
    <NavLink to="/my-profile" onClick={onHandle}>
        <div className="nav-hover d-flex align-items-center justify-content-between">
          <div className="profile-links">Edit Profile</div>
          <div className="prodile-arrow">
            <ProfileRightArrow />
          </div>
        </div>
      </NavLink>
      <NavLink to="/settings/notifications" onClick={onHandle}>
        <div className="nav-hover d-flex align-items-center justify-content-between mt_20">
          <div className="profile-links" >Settings</div>
          <div>
            <ProfileRightArrow />
          </div>
        </div>
      </NavLink>
      <div className="logout-hover d-flex align-items-center justify-content-between mt_20">
        <div onClick={() => setShowLogoutModal(true)} className="profile-links">Logout</div>
      </div>
    </Modal>
    <LogoutModal heading="Logout" type="logout" btntext="Logout" link="/signin" show={showlogoutmodal} setShowLogoutModal onHide={() => setShowLogoutModal(false)}/>
    </>
  );
}

export default ProfileModal;
