import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { AppleIcon, AppleLogo, FbIcon, GoogleLogo } from '../../constant/svg'

const SocialLogin = () => {
  return (
    <div className='social-login'>
        <div className='social-login-text'>or sign in with social accounts</div>
        <Row>
            <Col md={6}>
                <a target="_blank" href="https://appleid.apple.com/auth/authorize?client_id=com.beyondlive.services&redirect_uri=https%3A%2F%2Fauth.beyondlive.com%2Fusers%2Fapple%2Fsuccess&nonce=alsndklnalskdnlkanslkdn%3D%3D&response_type=code+id_token&scope=name+email&response_mode=form_post"><div><Button className="apple-btn"><AppleLogo/> <span>Continue with Apple</span></Button></div></a>
            </Col>
            <Col md={6}>
                <a target="_blank" href="https://accounts.google.com/v3/signin/identifier?dsh=1087974768442165996&continue=https%3A%2F%2Faccounts.google.com%2FManageAccount&flowName=GlifWebSignIn&flowEntry=ServiceLogin&ifkv=AeAAQh4dT-3kCRhcOmshofxtsW5ktDRrMnZthYlRNfOaTLIBz5agBihT_ycLL8bRQzw-7HD5DSyD"><div><Button className="google-btn"><GoogleLogo/><span>Continue with Google</span></Button></div></a>
            </Col>
            <Col md={6}>
                <a target="_blank" href="https://www.facebook.com/"><div><Button className="fb-btn"><FbIcon/><span>Continue with Facebook</span></Button></div></a>
            </Col>
        </Row>
    </div>
  )
}

export default SocialLogin