import React from "react";
import { Assets } from "../../constant/images";
import { NavLink } from "react-router-dom";
import { useState } from "react";

const BuyerOrders = () => {
  const [productDetailsConfirmed, setProductDetailsConfirmed] = useState([
    {
      orderId: "RW3456",
      date: "22,2022",
      status: "confirmed",
      subtotal: "$601.40",
      vat: "$0.00",

      shipping_rate: "Free",
      est_time: "14-16 Nov",
      total_amount: "$400.00",
      products: [
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 1,
          price: "$240.40",
        },
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 2,
          price: "$240.40",
        },
      ],
    },
    {
      orderId: "RW3454",
      date: "22,2022",
      status: "confirmed",
      subtotal: "$601.40",
      vat: "$0.00",
      total_amount: "$300.00",
      shipping_rate: "Free",
      est_time: "14-16 Nov",
      products: [
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 1,
          price: "$240.40",
        },
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 2,
          price: "$240.40",
        },
      ],
    },
  ]);

  const [productDetailsPending, setProductDetailsPending] = useState([
    {
      orderId: "RW3456",
      date: "22,2022",
      status: "pending",
      subtotal: "$601.40",
      vat: "$0.00",

      shipping_rate: "Free",
      est_time: "14-16 Nov",
      total_amount: "$400.00",
      products: [
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 1,
          price: "$240.40",
        },
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 2,
          price: "$240.40",
        },
      ],
    },
    {
      orderId: "RW3454",
      date: "22,2022",
      status: "pending",
      subtotal: "$601.40",
      vat: "$0.00",
      total_amount: "$300.00",
      shipping_rate: "Free",
      est_time: "14-16 Nov",
      products: [
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 1,
          price: "$240.40",
        },
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 2,
          price: "$240.40",
        },
      ],
    },
  ]);

  const [productDetailsCompleted, setProductDetailsCompleted] = useState([
    {
      orderId: "RW3456",
      date: "22,2022",
      status: "completed",
      subtotal: "$601.40",
      vat: "$0.00",

      shipping_rate: "Free",
      est_time: "14-16 Nov",
      total_amount: "$400.00",
      products: [
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 1,
          price: "$240.40",
        },
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 2,
          price: "$240.40",
        },
      ],
    },
    {
      orderId: "RW3454",
      date: "22,2022",
      status: "completed",
      subtotal: "$601.40",
      vat: "$0.00",
      total_amount: "$300.00",
      shipping_rate: "Free",
      est_time: "14-16 Nov",
      products: [
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 1,
          price: "$240.40",
        },
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 2,
          price: "$240.40",
        },
      ],
    },
  ]);

  const [productDetailsCancelled, setProductDetailsCancelled] = useState([
    {
      orderId: "RW3456",
      date: "22,2022",
      status: "cancelled",
      subtotal: "$601.40",
      vat: "$0.00",

      shipping_rate: "Free",
      est_time: "14-16 Nov",
      total_amount: "$400.00",
      products: [
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 1,
          price: "$240.40",
        },
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 2,
          price: "$240.40",
        },
      ],
    },
    {
      orderId: "RW3454",
      date: "22,2022",
      status: "cancelled",
      subtotal: "$601.40",
      vat: "$0.00",
      total_amount: "$300.00",
      shipping_rate: "Free",
      est_time: "14-16 Nov",
      products: [
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 1,
          price: "$240.40",
        },
        {
          img: Assets.Order1,
          name: "Engraved Gucci Watch",
          quantity: 2,
          price: "$240.40",
        },
      ],
    },
  ]);

  return (
    <>
      <div className="main-my-orders">
        <div className="tab-buttons-section">
          <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Confirmed
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Pending
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                Completed
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-cancelled-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-cancelled"
                type="button"
                role="tab"
                aria-controls="pills-cancelled"
                aria-selected="false"
              >
                Cancelled
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="main-order-count">
                <span>{productDetailsConfirmed.length} orders</span>
              </div>
              {productDetailsConfirmed.map((data) => (
                <>
                  <div className="order-card-wrapper">
                    <div className="orderid-view-details">
                      <div className="order-date">
                        <p>
                          Order ID: <span>{data.orderId}</span>
                        </p>
                        <p>
                          Date: <span>{data.date}</span>
                        </p>
                      </div>
                      <div className="view-details">
                        {/* <NavLink to={`/my-orders/${data.orderId}`}>
                          View Details
                        </NavLink> */}

                        <NavLink
                          to={`/my-orders/${data.orderId}`}
                          state={{ data: data }}
                        >
                          View Details
                        </NavLink>
                      </div>
                    </div>
                    {data.products.map((product) => (
                      <div className="order-details">
                        <div className="order-img-div">
                          <img className="img-fluid" src={product.img} />
                        </div>
                        <div className="order-content">
                          <h5>{product.name}</h5>
                          <span>Quantity: {product.quantity}</span>
                          <p>{product.price}</p>
                        </div>
                      </div>
                    ))}

                    {/* <div className="order-details">
                      <div className="order-img-div">
                        <img className="img-fluid" src={Assets.Order1} />
                      </div>
                      <div className="order-content">
                        <h5>Engraved Gucci Watch</h5>
                        <span>Quantity: 2</span>
                        <p>$240.40</p>
                      </div>
                    </div> */}

                    <div className="quantity-total-amount">
                      <p>{data.products.length} items</p>
                      <h5>
                        Total Amount: <span>$480.80</span>
                      </h5>
                    </div>
                  </div>

                  {/* <div className="order-card-wrapper">
                    <div className="orderid-view-details">
                      <div className="order-date">
                        <p>
                          Order ID: <span>#RW3456</span>
                        </p>
                        <p>
                          Date: <span>22,2022</span>
                        </p>
                      </div>
                      <div className="view-details">
                        <NavLink to="/my-orders-details-confirmed">
                          View Details
                        </NavLink>
                      </div>
                    </div>

                    <div className="order-details">
                      <div className="order-img-div">
                        <img className="img-fluid" src={Assets.Order1} />
                      </div>
                      <div className="order-content">
                        <h5>Engraved Gucci Watch</h5>
                        <span>Quantity: 2</span>
                        <p>$240.40</p>
                      </div>
                    </div>
                    <div className="order-details">
                      <div className="order-img-div">
                        <img className="img-fluid" src={Assets.Order1} />
                      </div>
                      <div className="order-content">
                        <h5>Engraved Gucci Watch</h5>
                        <span>Quantity: 2</span>
                        <p>$240.40</p>
                      </div>
                    </div>

                    <div className="quantity-total-amount">
                      <p>2 items</p>
                      <h5>
                        Total Amount: <span>$480.80</span>
                      </h5>
                    </div>
                  </div> */}
                </>
              ))}
            </div>

            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="main-order-count">
                <span>{productDetailsPending.length} orders</span>
              </div>
              {productDetailsPending.map((data) => (
                <>
                  <div className="order-card-wrapper">
                    <div className="orderid-view-details">
                      <div className="order-date">
                        <p>
                          Order ID: <span>{data.orderId}</span>
                        </p>
                        <p>
                          Date: <span>{data.date}</span>
                        </p>
                      </div>
                      <div className="view-details">
                        {/* <NavLink to={`/my-orders/${data.orderId}`}>
                          View Details
                        </NavLink> */}

                        <NavLink
                          to={`/my-orders/${data.orderId}`}
                          state={{ data: data }}
                        >
                          View Details
                        </NavLink>
                      </div>
                    </div>
                    {data.products.map((product) => (
                      <div className="order-details">
                        <div className="order-img-div">
                          <img className="img-fluid" src={product.img} />
                        </div>
                        <div className="order-content">
                          <h5>{product.name}</h5>
                          <span>Quantity: {product.quantity}</span>
                          <p>{product.price}</p>
                        </div>
                      </div>
                    ))}

                    {/* <div className="order-details">
                      <div className="order-img-div">
                        <img className="img-fluid" src={Assets.Order1} />
                      </div>
                      <div className="order-content">
                        <h5>Engraved Gucci Watch</h5>
                        <span>Quantity: 2</span>
                        <p>$240.40</p>
                      </div>
                    </div> */}

                    <div className="quantity-total-amount">
                      <p>{data.products.length} items</p>
                      <h5>
                        Total Amount: <span>$480.80</span>
                      </h5>
                    </div>
                  </div>

                  {/* <div className="order-card-wrapper">
                    <div className="orderid-view-details">
                      <div className="order-date">
                        <p>
                          Order ID: <span>#RW3456</span>
                        </p>
                        <p>
                          Date: <span>22,2022</span>
                        </p>
                      </div>
                      <div className="view-details">
                        <NavLink to="/my-orders-details-confirmed">
                          View Details
                        </NavLink>
                      </div>
                    </div>

                    <div className="order-details">
                      <div className="order-img-div">
                        <img className="img-fluid" src={Assets.Order1} />
                      </div>
                      <div className="order-content">
                        <h5>Engraved Gucci Watch</h5>
                        <span>Quantity: 2</span>
                        <p>$240.40</p>
                      </div>
                    </div>
                    <div className="order-details">
                      <div className="order-img-div">
                        <img className="img-fluid" src={Assets.Order1} />
                      </div>
                      <div className="order-content">
                        <h5>Engraved Gucci Watch</h5>
                        <span>Quantity: 2</span>
                        <p>$240.40</p>
                      </div>
                    </div>

                    <div className="quantity-total-amount">
                      <p>2 items</p>
                      <h5>
                        Total Amount: <span>$480.80</span>
                      </h5>
                    </div>
                  </div> */}
                </>
              ))}
            </div>

            <div
              class="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <div className="main-order-count">
                <span>{productDetailsCompleted.length} orders</span>
              </div>
              {productDetailsCompleted.map((data) => (
                <>
                  <div className="order-card-wrapper">
                    <div className="orderid-view-details">
                      <div className="order-date">
                        <p>
                          Order ID: <span>{data.orderId}</span>
                        </p>
                        <p>
                          Date: <span>{data.date}</span>
                        </p>
                      </div>
                      <div className="view-details">
                        {/* <NavLink to={`/my-orders/${data.orderId}`}>
                          View Details
                        </NavLink> */}

                        <NavLink
                          to={`/my-orders/${data.orderId}`}
                          state={{ data: data }}
                        >
                          View Details
                        </NavLink>
                      </div>
                    </div>
                    {data.products.map((product) => (
                      <div className="order-details">
                        <div className="order-img-div">
                          <img className="img-fluid" src={product.img} />
                        </div>
                        <div className="order-content">
                          <h5>{product.name}</h5>
                          <span>Quantity: {product.quantity}</span>
                          <p>{product.price}</p>
                        </div>
                      </div>
                    ))}

                    {/* <div className="order-details">
                      <div className="order-img-div">
                        <img className="img-fluid" src={Assets.Order1} />
                      </div>
                      <div className="order-content">
                        <h5>Engraved Gucci Watch</h5>
                        <span>Quantity: 2</span>
                        <p>$240.40</p>
                      </div>
                    </div> */}

                    <div className="quantity-total-amount">
                      <p>{data.products.length} items</p>
                      <h5>
                        Total Amount: <span>$480.80</span>
                      </h5>
                    </div>
                  </div>

                  {/* <div className="order-card-wrapper">
                    <div className="orderid-view-details">
                      <div className="order-date">
                        <p>
                          Order ID: <span>#RW3456</span>
                        </p>
                        <p>
                          Date: <span>22,2022</span>
                        </p>
                      </div>
                      <div className="view-details">
                        <NavLink to="/my-orders-details-confirmed">
                          View Details
                        </NavLink>
                      </div>
                    </div>

                    <div className="order-details">
                      <div className="order-img-div">
                        <img className="img-fluid" src={Assets.Order1} />
                      </div>
                      <div className="order-content">
                        <h5>Engraved Gucci Watch</h5>
                        <span>Quantity: 2</span>
                        <p>$240.40</p>
                      </div>
                    </div>
                    <div className="order-details">
                      <div className="order-img-div">
                        <img className="img-fluid" src={Assets.Order1} />
                      </div>
                      <div className="order-content">
                        <h5>Engraved Gucci Watch</h5>
                        <span>Quantity: 2</span>
                        <p>$240.40</p>
                      </div>
                    </div>

                    <div className="quantity-total-amount">
                      <p>2 items</p>
                      <h5>
                        Total Amount: <span>$480.80</span>
                      </h5>
                    </div>
                  </div> */}
                </>
              ))}
            </div>

            <div
              class="tab-pane fade"
              id="pills-cancelled"
              role="tabpanel"
              aria-labelledby="pills-cancelled-tab"
            >
              <div className="main-order-count">
                <span>{productDetailsCancelled.length} orders</span>
              </div>
              {productDetailsCancelled.map((data) => (
                <>
                  <div className="order-card-wrapper">
                    <div className="orderid-view-details">
                      <div className="order-date">
                        <p>
                          Order ID: <span>{data.orderId}</span>
                        </p>
                        <p>
                          Date: <span>{data.date}</span>
                        </p>
                      </div>
                      <div className="view-details">
                        {/* <NavLink to={`/my-orders/${data.orderId}`}>
                          View Details
                        </NavLink> */}

                        <NavLink
                          to={`/my-orders/${data.orderId}`}
                          state={{ data: data }}
                        >
                          View Details
                        </NavLink>
                      </div>
                    </div>
                    {data.products.map((product) => (
                      <div className="order-details">
                        <div className="order-img-div">
                          <img className="img-fluid" src={product.img} />
                        </div>
                        <div className="order-content">
                          <h5>{product.name}</h5>
                          <span>Quantity: {product.quantity}</span>
                          <p>{product.price}</p>
                        </div>
                      </div>
                    ))}

                    {/* <div className="order-details">
                      <div className="order-img-div">
                        <img className="img-fluid" src={Assets.Order1} />
                      </div>
                      <div className="order-content">
                        <h5>Engraved Gucci Watch</h5>
                        <span>Quantity: 2</span>
                        <p>$240.40</p>
                      </div>
                    </div> */}

                    <div className="quantity-total-amount">
                      <p>{data.products.length} items</p>
                      <h5>
                        Total Amount: <span>$480.80</span>
                      </h5>
                    </div>
                  </div>

                  {/* <div className="order-card-wrapper">
                    <div className="orderid-view-details">
                      <div className="order-date">
                        <p>
                          Order ID: <span>#RW3456</span>
                        </p>
                        <p>
                          Date: <span>22,2022</span>
                        </p>
                      </div>
                      <div className="view-details">
                        <NavLink to="/my-orders-details-confirmed">
                          View Details
                        </NavLink>
                      </div>
                    </div>

                    <div className="order-details">
                      <div className="order-img-div">
                        <img className="img-fluid" src={Assets.Order1} />
                      </div>
                      <div className="order-content">
                        <h5>Engraved Gucci Watch</h5>
                        <span>Quantity: 2</span>
                        <p>$240.40</p>
                      </div>
                    </div>
                    <div className="order-details">
                      <div className="order-img-div">
                        <img className="img-fluid" src={Assets.Order1} />
                      </div>
                      <div className="order-content">
                        <h5>Engraved Gucci Watch</h5>
                        <span>Quantity: 2</span>
                        <p>$240.40</p>
                      </div>
                    </div>

                    <div className="quantity-total-amount">
                      <p>2 items</p>
                      <h5>
                        Total Amount: <span>$480.80</span>
                      </h5>
                    </div>
                  </div> */}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerOrders;
