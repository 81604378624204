import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BuyerProfileSideMenu from "../../components/BuyerProfile/BuyerProfileSideMenu";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const BuyerProfileLayout = ({ children }) => {
  return (
    <>
      <Navbar />
      <section className="main-buyer-profile main-section">
        <Container>
          <Row>
            <Col xl={3}>
              <BuyerProfileSideMenu />
            </Col>
            <Col xl={9}>{children}</Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default BuyerProfileLayout;
