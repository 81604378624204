import React from "react";
import { Container } from "react-bootstrap";
import { Assets } from "../constant/images";
import { Row, Col, Button } from "react-bootstrap";
import SelectRole from "./AuthComponents/SelectRole";
import { BackArrow } from "../constant/svg";

const AuthLayout = (props,{isShow}) => {

  return (
    <div className="main-section">
              <div className="auth-container">
      <Container>
          <Row>
            <Col md={10} lg={6} xl={6}>
            <div className="auth-main-section-margin">
            <div>
                <img className="img-fluid" src={Assets.MainLogo} />
              </div>
              <div>
                <hr />
              </div>
              <div>
                {props.children}
              </div>
            </div>
            </Col>
          </Row>
      </Container>
      </div>

    </div>
  );
};

export default AuthLayout;
